import { ErrorHandler, SerializedErrorHandler } from "../model";
import { Workspace } from "../model/workspace";
import { nameSelector } from "../store/error-handlers/selectors";

import { DeleteEntity, GetEntity, SaveEntity } from "./entityService.types";
import { deleteFile, getFileDisplayName, readFile, saveFile } from "./fs-utils";

export const getErrorHandler: GetEntity<ErrorHandler> = async (
  id,
  workspace
) => {
  try {
    const file = await readFile(id, workspace, "errorHandlers");
    const fileName = getFileDisplayName(id, workspace, "errorHandlers");
    return deserializeErrorHandler(
      id,
      fileName,
      file as SerializedErrorHandler
    );
  } catch (e) {
    console.error(e);
    throw new Error(`ErrorHandler with the id "${id}" can not be retrieved`);
  }
};

export const saveErrorHandler: SaveEntity<ErrorHandler> = async (
  errorHandler,
  workspace
) => {
  const name = nameSelector(errorHandler);

  try {
    const serialized = serializeErrorHandler(errorHandler);
    await saveFile(
      errorHandler.id,
      workspace,
      "errorHandlers",
      serialized,
      name
    );
    return errorHandler;
  } catch (e) {
    console.error(e);
    throw new Error(`ErrorHandler "${name}" can not be saved`);
  }
};

export const deleteErrorHandler: DeleteEntity = async (
  id: string,
  workspace: Workspace
) => {
  try {
    await deleteFile(id, workspace, "errorHandlers");
  } catch (e) {
    console.error(e);
    throw new Error(`Error handler with the id "${id}" can not be deleted`);
  }
};

export const deserializeErrorHandler = (
  id: string,
  fileName: string,
  fileErrorHandler: SerializedErrorHandler
): ErrorHandler => ({
  ...fileErrorHandler,
  name: fileName,
  id,
});

export const serializeErrorHandler = (
  job: ErrorHandler
): SerializedErrorHandler => {
  const { id, name, ...rest } = job;
  return { ...rest };
};
