import React from "react";
import { SvgIcon } from "@mui/material";

const ACELogo: React.FC = () => {
  return (
    <SvgIcon viewBox={"0 0 40 30"} style={{ width: "40px", height: "30px" }}>
      <path
        d="M39.7873 29.0323L28.9362 1.1828C28.7235 0.537634 28.4043 0.107527 28.1915 0C28.0852 0 27.5532 0 26.8086 0H22.3405C21.383 0 20.7447 0.322581 20.4256 1.1828L9.46814 29.0323C9.36175 29.3548 9.25537 29.5699 9.25537 29.7849C9.25537 29.8925 9.36175 30 9.6809 30H15.0001C15.4256 30 15.7447 29.7849 15.8511 29.4624L16.0639 28.9247L16.9149 26.7742L17.8724 24.3011L24.5745 7.63441L28.8298 18.0645L31.2767 24.1936L33.4043 29.3548C33.5107 29.6774 33.8298 29.8925 34.2554 29.8925H39.7873C40.0001 30 40.0001 29.6774 39.7873 29.0323Z"
        fill="white"
      />
      <path
        d="M0 17.3118C12.1277 23.6559 21.383 21.5054 32.234 13.7634C37.9787 9.67741 41.1702 16.4516 35.4255 20.1075C23.4043 27.6344 11.383 25.4839 0 17.3118Z"
        fill="#009FE3"
      />
    </SvgIcon>
  );
};

export default ACELogo;
