import { FC, HTMLAttributes } from "react";

export const AceForm: FC<
  Omit<HTMLAttributes<HTMLFormElement>, "noValidate">
> = (props) => {
  const { children, ...restProps } = props;
  return (
    <form {...restProps} noValidate>
      {children}
    </form>
  );
};
