import { createSelector } from "@reduxjs/toolkit";

import { Schedule } from "../../model/schedules";
import { GenericState, Redoable } from "../utils/redoableSliceFactory";
import { selectEntity } from "../utils/redoableSliceSelectors";
import { RootState } from "..";

export const selectSchedules = (state: RootState): GenericState<Schedule> =>
  state.schedules;

export const selectScheduleEntity = (
  state: RootState,
  flowId: string
): Redoable<Schedule> | undefined => selectEntity(state.schedules, flowId);

export const selectSchedule = createSelector(
  selectScheduleEntity,
  (s) => s?.present
);

export const selectPresentSchedules = createSelector(
  selectSchedules,
  (schedules: GenericState<Schedule>) => schedules.map((s) => s.present)
);
export const nameSelector = (job: Schedule): string => job.name;
