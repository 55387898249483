import { createAction } from "@reduxjs/toolkit";

export const selectFlowTabAction = createAction<string>(
  "workspace/selectFlowTab"
);
export const closeFlowTabAction = createAction<string>(
  "workspace/closeFlowTab"
);

export const reorderFlowTabAction = createAction<{
  fromIdx: number;
  toIdx: number;
}>("workspace/reorderFlowTabs");
