/**
 * The indexer has information about usage of entities
 * For eg:
 * some flow is used in other flows or apis or schedulers
 * Sample value stored in indexes is as below:
 *
 * {
 *
 * "idOfFlow":{
 *      flows: <"someotherflowid">,
 *      apis: <"someapiid">
 *      schedulers: <"someschedulerid">
 *     }
 *  }
 */
import { FlowStatus } from "@sapiens-digital/ace-designer-common";
import { DeepPartial } from "redux";

import { FLOW_METADATA_UPDATED_EVENT_NAME } from "../flows";

type Relations = Partial<Record<IndexableEntityTypes, Set<string>>>;
type FlowMetadata = { status: FlowStatus };
type Metadata = DeepPartial<{ data: FlowMetadata }>;

export type FileIndex = Relations & Metadata;

interface Indexes {
  [key: string]: FileIndex;
}

const indexes: Indexes = {};

export const INDEXABLE_ENTITY_TYPES = {
  flows: "flows",
  schedulers: "schedulers",
  operations: "operations",
  schemas: "schemas",
  virtualSteps: "virtualSteps",
} as const;
export type IndexableEntityTypes = keyof typeof INDEXABLE_ENTITY_TYPES;

export const modifyEntityIndex = (
  consumerId: string,
  consumerType: IndexableEntityTypes,
  existingEntityUsage: FileIndex | undefined
): FileIndex => ({
  ...existingEntityUsage,
  [consumerType]: (
    existingEntityUsage?.[consumerType] ?? new Set<string>()
  ).add(consumerId),
});

export const removeUsageOfEntity = (
  consumerId: string,
  consumerType: IndexableEntityTypes
): void => {
  Object.keys(indexes).forEach((entityId) => {
    indexes[entityId]?.[consumerType]?.delete(consumerId);
  });
};

export const removeSpecificUsageOfEntity = (
  entityId: string,
  consumerId: string,
  consumerType: IndexableEntityTypes
): void => {
  indexes[entityId]?.[consumerType]?.delete(consumerId);
};

export const updateEntityIndexes = (
  entityId: string,
  consumerId: string,
  consumerType: IndexableEntityTypes
): void => {
  const modifiedIndex = modifyEntityIndex(
    consumerId,
    consumerType,
    getEntityIndexes(entityId)
  );

  indexes[entityId] = {
    ...indexes[entityId],
    ...modifiedIndex,
  };
};

export const addMetadata = (id: string, data: Metadata["data"]): void => {
  const existingIndex = getEntityIndexes(id);

  if (existingIndex) {
    indexes[id] = {
      ...existingIndex,
      data: {
        ...existingIndex.data,
        ...data,
      },
    };
  } else {
    indexes[id] = {
      data,
    };
  }

  dispatchEvent(
    new CustomEvent(FLOW_METADATA_UPDATED_EVENT_NAME, {
      detail: { id },
    })
  );
};

export const doesEntityHaveConsumers = (entityIds: string[]): boolean => {
  for (const entityId of entityIds) {
    const index = getEntityIndexes(entityId);

    for (const entityType in INDEXABLE_ENTITY_TYPES) {
      if (index?.[entityType as IndexableEntityTypes]?.size) {
        return true;
      }
    }
  }

  return false;
};

export const getEntityIndexes = (entityId: string): FileIndex | undefined =>
  indexes[entityId];

export const getEntityMetadata = (entityId: string): Metadata["data"] =>
  getEntityIndexes(entityId)?.data;

export const getFlowStatus = (flowId: string): FlowStatus =>
  getEntityMetadata(flowId)?.status;

export const cleanIndexes = (): void => {
  Object.keys(indexes).forEach((id) => {
    delete indexes[id];
  });
};
