import { AsyncZippable, strToU8, zip } from "fflate";

import { getWorkspaceFS } from "./workspace";

export const downloadFS = async (rootPath = "/"): Promise<Uint8Array> => {
  const fs = getWorkspaceFS().promises;

  const getFileData = async (filePath: string): Promise<string | undefined> => {
    try {
      return await fs.readFile(filePath, {
        encoding: "utf8",
      });
    } catch (e) {
      console.error(e);
    }
  };

  const getFolderData = async (path: string): Promise<AsyncZippable> => {
    try {
      const folderFiles: AsyncZippable = {};
      const fileNames = (await fs.readdir(path)) as string[];

      for (const fileName of fileNames) {
        const filePath = `${path}/${fileName}`;
        const stat = await fs.stat(filePath);

        if (stat.isDirectory()) {
          folderFiles[fileName] = await getFolderData(filePath);
          continue;
        }

        if (!stat.isFile()) continue;

        const fileData = await getFileData(filePath);

        if (fileData !== undefined) {
          folderFiles[fileName] = strToU8(fileData);
        }
      }

      return folderFiles;
    } catch (e) {
      console.error(e);
      return {};
    }
  };

  const folderData = await getFolderData(rootPath);
  return new Promise((res, rej) =>
    zip(folderData, { level: 1 }, (err, zippedFolder) => {
      if (err) return rej(err);
      res(zippedFolder);
    })
  );
};
