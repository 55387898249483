import { EnvVariable, EnvVariables } from "../../model/variable";

export const removeVariableMutator = (
  variableFile: EnvVariables,
  variableId: string
): void => {
  variableFile.variables = variableFile.variables.filter(
    (envVariable) => variableId !== envVariable.id
  );
};

export function insertVariableMutator(
  variableFile: EnvVariables,
  variable: EnvVariable
): void {
  variableFile.variables.push(variable);
}

export function updateVariableMutator(
  variableFile: EnvVariables,
  variable: EnvVariable
): void {
  const envVar = variableFile.variables.find((v) => v.id === variable.id);
  if (envVar) {
    envVar.name = variable.name;
    envVar.value = variable.value;
  }
}
