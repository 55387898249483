import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { isRejected } from "@reduxjs/toolkit";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import {
  Button,
  DialogActions,
  DialogContent,
  LinearProgress,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  TextField,
} from "@mui/material";
import Avatar from "@mui/material/Avatar";
import IconButton from "@mui/material/IconButton";
import { styled } from "@mui/material/styles";

import { Remote, Workspace } from "../../model/workspace";
import { RootState, useAppDispatch } from "../../store";
import {
  closeWorkspaceManager,
  setDesignerLoading,
} from "../../store/designer/reducers";
import { switchWorkspaceAction } from "../../store/settings/actions";
import { deleteWorkspaceAction } from "../../store/workspaces/actions";
import { workspaceSelectors } from "../../store/workspaces/selectors";
import WorkspaceIcon from ".././icons/Workspace";
import { notify, notifyError } from "../utils/notify";

import { DeleteWorkspaceDialog } from "./DeleteWorkspaceDialog";

const WorkspaceListItemContainer = styled("div")(({ theme }) => ({
  display: "flex",
  justifyContent: "space-between",
  "&:hover": {
    backgroundColor: theme.palette.grey[300],
    cursor: "pointer",
  },
}));

const WorkspaceManagerLocal: React.FC = () => {
  const [open, setOpen] = React.useState(false);
  const [workspaceToDelete, setWorkspaceToDelete] = React.useState(
    undefined as Remote | undefined
  );
  const [deleteInProgress, setDeleteInProgress] = useState<
    Record<string, boolean>
  >({});

  const [search, setSearch] = useState("");
  const [inProgress, setInProgress] = useState(false);

  const dispatch = useAppDispatch();

  const workspaces = useSelector<RootState, Array<Workspace>>((state) =>
    workspaceSelectors.selectAll(state)
  );
  workspaces.sort();

  const [items, setItems] = useState(workspaces);

  useEffect(() => {
    setItems(workspaces);
  }, [workspaces]);

  const filterItems = (value: string) => {
    setSearch(value);
    setItems(
      workspaces.filter((item) =>
        item.name.toLowerCase().includes(value.trim().toLowerCase())
      )
    );
  };

  const handleDelete = async (remote: Remote) => {
    deleteInProgress[remote.id] = true;
    setDeleteInProgress({ ...deleteInProgress });

    const deleteResult = await dispatch(deleteWorkspaceAction(remote.id));
    notify(deleteResult, dispatch, `Workspace ${remote.name} is deleted`);

    deleteInProgress[remote.id] = false;
    setDeleteInProgress({ ...deleteInProgress });
  };

  const handleOpen = (workspace: Workspace) => {
    setOpen(true);
    setWorkspaceToDelete(workspace);
  };

  const handleClose = () => {
    setOpen(false);
    setWorkspaceToDelete(undefined);
  };

  let content;

  const switchWorkspace = async (workspaceId: string) => {
    setInProgress(true);
    const switchWorkspaceResult = await dispatch(
      switchWorkspaceAction(workspaceId)
    );

    if (isRejected(switchWorkspaceResult)) {
      notifyError(switchWorkspaceResult, dispatch);
      setInProgress(false);
    } else {
      dispatch(closeWorkspaceManager());
    }
  };

  if (inProgress) {
    content = <LinearProgress />;
  } else {
    content = (
      <>
        <TextField
          variant="standard"
          fullWidth
          autoFocus
          label="Search"
          value={search}
          onChange={(event) => filterItems(event.target.value)}
        />
        <List sx={{ overflowY: "auto", marginTop: "20px", height: "40vh" }}>
          {items.map((item) => (
            <WorkspaceListItemContainer key={`workspace-${item.id}`}>
              <ListItemButton
                key={item.id}
                disabled={deleteInProgress[item.id]}
                onClick={async () => {
                  dispatch(setDesignerLoading(true));
                  await switchWorkspace(item.id);
                  dispatch(setDesignerLoading(false));
                }}
              >
                <ListItemIcon>
                  <WorkspaceIcon />
                </ListItemIcon>
                <ListItemText>{item.name}</ListItemText>
              </ListItemButton>
              <IconButton
                disabled={deleteInProgress[item.id]}
                onClick={() => {
                  handleOpen(item);
                }}
                sx={{
                  color: "grey.700",
                  "&:hover": {
                    backgroundColor: "transparent",
                    color: "danger",
                  },
                }}
                size="large"
              >
                <Avatar sx={{ color: "inherit", backgroundColor: "grey.400" }}>
                  <DeleteOutlineIcon />
                </Avatar>
              </IconButton>
            </WorkspaceListItemContainer>
          ))}
        </List>
        {workspaceToDelete && (
          <DeleteWorkspaceDialog
            open={open}
            workspace={workspaceToDelete}
            handleClose={handleClose}
            handleDelete={handleDelete}
          />
        )}
      </>
    );
  }

  return (
    <>
      <DialogContent>{content}</DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            dispatch(closeWorkspaceManager());
          }}
          color="primary"
        >
          Cancel
        </Button>
      </DialogActions>
    </>
  );
};

export default WorkspaceManagerLocal;
