import { REGX_FOR_VALID_STRING_EXPRESSION, Steps, StepSchema } from "../model";

export const mapStepSchema: StepSchema = {
  stepType: Steps.MAP,
  color: "rgb(245,210,211)",
  jsonSchema: {
    title: "JSON Map",
    description: "Map Enrich JSON step definition",
    type: "object",
    properties: {
      name: {
        type: "string",
        title: "Name",
      },
      stepType: {
        type: "string",
        title: "stepType",
      },
      description: {
        type: "string",
        title: "Step Description",
      },
      condition: {
        type: "string",
        title:
          "An optional condition on the step. If returns false step will be skipped (e.g {{input.age>10}})",
        pattern: REGX_FOR_VALID_STRING_EXPRESSION,
      },
      config: {
        type: "object",
        title: "Configuration",
        required: [],
        properties: {
          parallel: {
            type: "boolean",
            title: "Execute map operations in parallel",
            default: false,
            enum: [true, false],
          },
          ignoreMissingSourcePath: {
            type: "boolean",
            title: "Ignore missing input source",
            default: false,
            enum: [true, false],
          },
          maps: {
            type: "array",
            title: "Mappings",
            items: {
              type: "object",
              required: ["targetPath"],
              properties: {
                sourcePath: {
                  type: "string",
                  title: "Source path - full source path for the source data",
                },
                sourcePartialPath: {
                  type: "string",
                  title:
                    "Source partial path (optional) - if the partial path is unique, set the partial path to the source data",
                },
                value: {
                  type: "string",
                  title:
                    "Value - static content to be placed in target path (use one of sourcePath, sourcePartialPath or value)",
                },
                targetPath: {
                  type: "string",
                  title:
                    "Target path - target location of the source data inside the document",
                },
                append: {
                  type: "boolean",
                  title:
                    "Append - if true the result will be appended to an array defined as the target path",
                },
                delete: {
                  type: "boolean",
                  title:
                    "Delete - if true the targetPath will be deleted. In this case there is no need for source data",
                },
                asString: {
                  type: "boolean",
                  title:
                    "As string -  if true the source data will be set to a string",
                },
                regex: {
                  type: "object",
                  title: "Regex Configuration",
                  properties: {
                    match: {
                      type: "string",
                      title:
                        "Match - Match an expression from the source path and place inside target path find spaces, brackets etc",
                    },
                    replace: {
                      type: "string",
                      title:
                        "Replace - after matching an expression, it is possible to define a string that will replace the content",
                    },
                  },
                },
              },
            },
          },
        },
      },
    },
  },
  uiSchema: {
    stepType: {
      "ui:widget": "hidden",
    },
    name: {
      classNames: "hidden-ace4",
    },
    description: {
      "ui:widget": "textarea",
    },
  },
};
