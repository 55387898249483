import PromisifiedFS from "@isomorphic-git/lightning-fs";
import { WorkspaceSettings } from "@sapiens-digital/ace-designer-app/app/model/workspace";
import {
  Environment,
  GetWorkspaceFS,
  GetWorkspaceHttpClient,
} from "@sapiens-digital/ace-designer-app/app/services/workspace";
import http from "isomorphic-git/http/web";

const fs = new PromisifiedFS("ACE");

export interface LoadWorkspaceSettings {
  (): Promise<WorkspaceSettings>;
}

export interface SaveWorkspaceSettings {
  (settings: WorkspaceSettings): Promise<void>;
}

export const getWorkspaceFS: GetWorkspaceFS = () => {
  return fs;
};

export const getWorkspaceHttpClient: GetWorkspaceHttpClient = () => {
  return http;
};

export const getEnvironment = (): Environment => {
  return Environment.Web;
};
