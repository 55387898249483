import { createAsyncThunk } from "@reduxjs/toolkit";

import {
  moveChangesToNewBranch,
  resetToLocal,
  resetToRemote,
} from "../../services/git-utils";
import { RootState } from "../index";
import { selectRemoteAction } from "../remotes/actions";
import { assertSettings, assertWorkspace } from "../utils/assertEntities";

import { loadAndSetupWorkspaceAction } from "./actions";
import { selectSelectedWorkspace } from "./selectors";

export const resetWorkspaceToRemoteAction = createAsyncThunk<
  void,
  void,
  { state: RootState }
>("workspaces/resetToRemote", async (_, { getState, dispatch }) => {
  const workspace = selectSelectedWorkspace(getState());
  const settings = getState().settings;

  assertWorkspace(workspace);
  assertSettings(settings);
  await resetToRemote(
    workspace.name,
    settings.repositoryUrl,
    settings.repositoryToken,
    settings.repositoryUsername
  );
  await dispatch(
    loadAndSetupWorkspaceAction({
      ...settings,
      workspaceName: workspace.name,
    })
  ).unwrap();
});

export const resetRemoteToWorkspaceAction = createAsyncThunk<
  void,
  void,
  { state: RootState }
>("workspaces/resetRemoteToWorkspace", async (_, { getState, dispatch }) => {
  const workspace = selectSelectedWorkspace(getState());
  const settings = getState().settings;

  assertWorkspace(workspace);
  assertSettings(settings);
  await resetToLocal(
    workspace.name,
    settings.repositoryUrl,
    settings.repositoryToken,
    settings.repositoryUsername
  );
  await dispatch(
    loadAndSetupWorkspaceAction({
      ...settings,
      workspaceName: workspace.name,
    })
  ).unwrap();
});

export const moveWorkspaceToAnotherBranchAction = createAsyncThunk<
  void,
  string,
  { state: RootState }
>(
  "workspaces/moveToAnotherBranch",
  async (newBranchName, { getState, dispatch }) => {
    const workspace = selectSelectedWorkspace(getState());
    const settings = getState().settings;

    assertWorkspace(workspace);
    assertSettings(settings);
    await moveChangesToNewBranch(
      newBranchName,
      workspace.name,
      settings.repositoryUrl,
      settings.repositoryToken,
      settings.repositoryUsername
    );
    await dispatch(selectRemoteAction(newBranchName)).unwrap();
  }
);
