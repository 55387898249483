import { useAppDispatch } from "../../store";
import { refreshAndSetupWorkspaceAction } from "../../store/workspaces/actions";

import { notify } from "./notify";

export function useWorkspaceRefresh(): {
  handleRefresh: () => void;
} {
  const dispatch = useAppDispatch();

  const handleRefresh = async () => {
    const refreshResult = await dispatch(refreshAndSetupWorkspaceAction());
    notify(refreshResult, dispatch, "Workspace is updated");
  };

  return {
    handleRefresh,
  };
}
