import moment from "moment";

export interface FilterableListItem {
  id: string;
  date?: string;
  name?: string;
  path?: string;
  summary?: string;
  value?: string;
}

export type FilterableKeys = keyof FilterableListItem;

export interface Filter {
  ids: string[];
}

export interface Filterable {
  id: string;
}

export interface FilterComponentProps<T> {
  targets: T[];
  onFilter: (idsAfterFilter: string[]) => void;
}

export function idsFrom(targets: Filterable[]): string[] {
  return targets.map((t) => t.id);
}

export function dereference<T extends Filterable>(
  src: T[],
  id: string
): T | undefined {
  return src.find((f) => f.id === id);
}

export function dereferenceInOrder<T extends Filterable>(
  source: T[],
  orderedIds: string[]
): T[] {
  const dereferenced = orderedIds.map((id) => dereference(source, id));
  const validated = dereferenced.filter((s) => s !== undefined);
  return validated as T[];
}

export type SortResult = 1 | 0 | -1;

export function matchAnyCase(
  value: string | undefined,
  expected: string | undefined
): boolean {
  if (value === undefined || expected === undefined) {
    return false;
  }

  return value.toLowerCase().includes(expected.toLowerCase());
}

export function sortAscInsensitive(
  a: string | undefined = "",
  b: string | undefined = ""
): SortResult {
  const nameA = a.toUpperCase();
  const nameB = b.toUpperCase();

  if (nameA < nameB) {
    return -1;
  }

  if (nameA > nameB) {
    return 1;
  }

  return 0;
}

export function sortNewest(
  a: string | undefined,
  b: string | undefined
): SortResult {
  const dateA = a ? moment(a, "MM/DD/YYYY") : "";
  const dateB = b ? moment(b, "MM/DD/YYYY") : "";

  if (dateA > dateB) {
    return -1;
  }

  if (dateA < dateB) {
    return 1;
  }

  return 0;
}

const SORTER_FUNCTIONS = {
  string: sortAscInsensitive,
  date: sortNewest,
};

export const getOrderSorterByProperty = (
  ascName: string,
  descName: string,
  propertyName: FilterableKeys,
  type: keyof typeof SORTER_FUNCTIONS = "string"
): Record<
  string,
  (a: FilterableListItem, b: FilterableListItem) => SortResult
> => ({
  [ascName]: (a: FilterableListItem, b: FilterableListItem) =>
    SORTER_FUNCTIONS[type](a[propertyName], b[propertyName]),
  [descName]: (a: FilterableListItem, b: FilterableListItem) =>
    SORTER_FUNCTIONS[type](b[propertyName], a[propertyName]),
});
