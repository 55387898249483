import { PayloadAction } from "@reduxjs/toolkit";

import { EnvVariable } from "../../model/variable";
import { createRedoableSlice } from "../utils/redoableSliceFactory";
import { selectEntity } from "../utils/redoableSliceSelectors";

import {
  deleteVariableAction,
  openEnvironementVariablesAction,
  refreshVariablesAction,
} from "./actions";
import {
  insertVariableMutator,
  removeVariableMutator,
  updateVariableMutator,
} from "./mutator";
import { name } from "./sliceName";

const variableSlice = createRedoableSlice({
  name,
  reducers: {
    addVariable(
      state,
      action: PayloadAction<{ variableFileId: string; variable: EnvVariable }>
    ) {
      const { variableFileId, variable } = action.payload;
      const variables = selectEntity(state, variableFileId);
      if (!variables) return state;

      insertVariableMutator(variables.present, variable);
    },
    updateVariable(
      state,
      action: PayloadAction<{
        variableFileId: string;
        variable: EnvVariable;
      }>
    ) {
      const { variableFileId, variable } = action.payload;
      const variables = selectEntity(state, variableFileId);
      if (!variables) return state;
      updateVariableMutator(variables.present, variable);
    },
  },
  predefinedThunks: {
    openEntityAction: openEnvironementVariablesAction,
    refreshEntitiesAction: refreshVariablesAction,
  },
  extraReducers: (builder) => {
    builder.addCase(
      deleteVariableAction,
      (
        state,
        action: PayloadAction<{
          variableFileId: string;
          variableId: string;
        }>
      ) => {
        const { variableFileId, variableId } = action.payload;
        const variable = selectEntity(state, variableFileId);
        if (!variable) return state;
        removeVariableMutator(variable.present, variableId);
      }
    );
  },
});

export const {
  update,
  add,
  remove,
  redo,
  undo,
  addVariable,
  updateVariable,
} = variableSlice.actions;

export default variableSlice.reducer;
