import faker, { Schema } from "json-schema-faker";

faker.option({
  alwaysFakeOptionals: true,
  fixedProbabilities: true,
  useDefaultValue: true,
});

export function generateSampleObjectFromSchema(schema: Schema): unknown {
  return faker.generate(schema);
}
