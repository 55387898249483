import { REGX_FOR_VALID_STRING_EXPRESSION, Steps, StepSchema } from "../model";

export const deepMergeStepSchema: StepSchema = {
  stepType: Steps.DEEP_MERGE,
  color: "rgb(204,255,153)",
  jsonSchema: {
    title: "Deep Merge",
    description: "Deep merge objects",
    type: "object",
    properties: {
      name: {
        type: "string",
        title: "Name",
      },
      stepType: {
        type: "string",
        title: "stepType",
      },
      description: {
        type: "string",
        title: "Step Description",
      },
      condition: {
        type: "string",
        title:
          "An optional condition on the step. If returns false step will be skipped (e.g {{input.age>10}})",
        pattern: REGX_FOR_VALID_STRING_EXPRESSION,
      },
      config: {
        type: "object",
        title: "Configuration",
        required: ["targetPath"],
        properties: {
          objectPaths: {
            type: "array",
            title: "Paths of the objects to merge",
            items: {
              type: "object",
              required: ["objectPath"],
              properties: {
                objectPath: {
                  type: "string",
                  title: "Path of the object to merge",
                },
              },
            },
          },
          customFunctionOptionKey: {
            title: "custom Function Options",
            type: "string",
            enum: ["customMerge", "arrayMerge", "isMergeableObject"],
            default: "customMerge",
          },
          customMergeFunction: {
            type: "string",
            title:
              "Function which can be used to override the default merge behavior for a property, based on the property name " +
              '(e.g (key) => {if (key === "key") { return () => {}}})',
          },
          targetPath: {
            type: "string",
            title: "Target path - define the path in which to store the result",
          },
        },
      },
    },
  },
  uiSchema: {
    stepType: {
      "ui:widget": "hidden",
    },
    name: {
      classNames: "hidden-ace4",
    },
    description: {
      "ui:widget": "textarea",
    },
    config: {
      customMergeFunction: {
        "ui:field": "js",
      },
    },
  },
};
