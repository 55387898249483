import { getFileNameFromRegistry } from "../../services/fs-utils";
import { RootState } from "../index";

import { Entity, Redoable } from "./redoableSliceFactory";
import { getRedoableSlice, selectEntity } from "./redoableSliceSelectors";

/**
 * Utility for redux thunks.
 * Returns the target path of the entity with the given id.
 * @param getState
 * @param sliceName
 * @param id
 */
export const getEntityTargetPath = (
  getState: () => RootState,
  sliceName: string,
  id: string
): string | undefined => {
  const redoableSlice = getRedoableSlice(getState(), sliceName);
  const targetFolder = selectEntity(redoableSlice, id)?.targetFolderId;
  return targetFolder ? getFileNameFromRegistry(targetFolder) : undefined;
};

export const getEntityTargetFolderPath = <T extends Entity>(
  entity?: Redoable<T>
): string => {
  if (!entity?.targetFolderId) return "";

  return getFileNameFromRegistry(entity?.targetFolderId) ?? "";
};
