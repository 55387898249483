import { createAsyncThunk } from "@reduxjs/toolkit";

import { VirtualStep } from "../../model/virtualSteps";
import { pushDeletedFolderToGit } from "../../services/git-utils";
import {
  deleteVirtualStep,
  getVirtualStep,
  getVirtualStepId,
  moveVirtualStep,
  saveVirtualStep,
  serializeVirtualStep,
} from "../../services/virtualSteps";
import {
  addEntityToGitActionCreator,
  deleteAndPushActionCreator,
  deleteEntityActionCreator,
  deleteFolderActionCreator,
  moveAndPushActionCreator,
  moveEntityActionCreator,
  moveFolderActionCreator,
  openEntityActionCreator,
  refreshEntitiesActionCreator,
  renameFolderActionCreator,
  storeAndPushActionCreator,
  storeEntityActionCreator,
  storeFolderActionCreator,
} from "../utils/actionFactory";
import { assertWorkspace } from "../utils/assertEntities";
import { selectSelectedWorkspace } from "../workspaces/selectors";
import { RootState } from "..";

import { nameSelector } from "./selectors";
import { name } from "./sliceName";

export const openVirtualStepAction = openEntityActionCreator(
  name,
  getVirtualStep
);

export const refreshVirtualStepsAction = refreshEntitiesActionCreator(
  name,
  getVirtualStep,
  serializeVirtualStep
);

export const storeVirtualStepsAction = storeEntityActionCreator(
  name,
  saveVirtualStep,
  "virtualSteps"
);

export const deleteVirtualStepsAction = deleteEntityActionCreator(
  name,
  deleteVirtualStep,
  "virtualSteps"
);

export const moveVirtualStepAction = moveEntityActionCreator(
  name,
  moveVirtualStep,
  "virtualSteps"
);

export const moveVirtualStepsFolderAction = moveFolderActionCreator(
  name,
  "virtualSteps"
);
export const overwriteVirtualStepsFolderAction = moveFolderActionCreator(
  name,
  "virtualSteps",
  "overwrite"
);

export const storeVirtualStepsFolderAction = storeFolderActionCreator(
  name,
  "virtualSteps"
);

export const renameVirtualStepsFolderAction = renameFolderActionCreator(
  name,
  "virtualSteps"
);

export const deleteVirtualStepsFolderAction = deleteFolderActionCreator(
  name,
  "virtualSteps"
);

export const addVirtualStepToGitAction = addEntityToGitActionCreator(
  name,
  "virtualSteps"
);

export const addRemovedVirtualStepsFolderToGitAction = addEntityToGitActionCreator(
  name,
  "virtualSteps",
  pushDeletedFolderToGit
);

export const storeAndPushVirtualStepAction = storeAndPushActionCreator(
  name,
  nameSelector,
  storeVirtualStepsAction,
  addVirtualStepToGitAction
);

export const deleteAndPushVirtualStepAction = deleteAndPushActionCreator(
  name,
  deleteVirtualStepsAction,
  addVirtualStepToGitAction
);

export const deleteAndPushVirtualStepsFolderAction = deleteAndPushActionCreator(
  name,
  deleteVirtualStepsFolderAction,
  addRemovedVirtualStepsFolderToGitAction
);

export const moveAndPushVirtualStepAction = moveAndPushActionCreator(
  name,
  moveVirtualStepAction,
  addVirtualStepToGitAction
);

export const openVirtualStepByPathAction = createAsyncThunk<
  VirtualStep,
  string,
  { state: RootState }
>(`${name}/open`, async (path, { getState, dispatch }) => {
  const workspace = selectSelectedWorkspace(getState());
  assertWorkspace(workspace);
  const id = await getVirtualStepId(path, workspace);
  return await dispatch(openVirtualStepAction(id)).unwrap();
});
