import { Schedule, SerializedSchedule } from "../model/schedules";
import { Workspace } from "../model/workspace";
import { nameSelector } from "../store/schedules/selectors";

import { removeUsageOfEntity } from "./indexing/indexer";
import { updateUsage } from "./indexing/updateUsage";
import { DeleteEntity, GetEntity, SaveEntity } from "./entityService.types";
import { deleteFile, getFileDisplayName, readFile, saveFile } from "./fs-utils";

export const getSchedule: GetEntity<Schedule> = async (id, workspace) => {
  try {
    const file = await readFile(id, workspace, "schedules");
    const fileName = getFileDisplayName(id, workspace, "schedules");
    const schedule = deserializeSchedule(
      id,
      fileName,
      file as SerializedSchedule
    );
    updateScheduleUsage(schedule, workspace);
    return schedule;
  } catch (e) {
    console.error(e);
    throw new Error(`Schedule with the id "${id}" can not be retrieved`);
  }
};

export const saveSchedule: SaveEntity<Schedule> = async (
  schedule,
  workspace
) => {
  const name = nameSelector(schedule);

  try {
    const serialized = serializeSchedule(schedule);
    await saveFile(schedule.id, workspace, "schedules", serialized, name);
    updateScheduleUsage(schedule, workspace);
    return schedule;
  } catch (e) {
    console.error(e);
    throw new Error(`Schedule "${name}" can not be saved`);
  }
};

export const deleteSchedule: DeleteEntity = async (
  id: string,
  workspace: Workspace
) => {
  try {
    await deleteFile(id, workspace, "schedules");
    removeUsageOfEntity(id, "schedulers");
  } catch (e) {
    console.error(e);
    throw new Error(`Schedule with the id "${id}" can not be deleted`);
  }
};

export const deserializeSchedule = (
  id: string,
  fileName: string,
  fileSchedule: SerializedSchedule
): Schedule => ({
  ...fileSchedule,
  name: fileName,
  id,
});

export const serializeSchedule = (job: Schedule): SerializedSchedule => {
  const { id, name, ...rest } = job;
  return rest;
};

export type SchedulerUsage = {
  flowName?: string;
};

export const updateScheduleUsage = (
  scheduler: Schedule,
  workspace: Workspace
): void => {
  updateUsage(
    { id: scheduler.id, entityType: "schedulers" },
    { flows: [scheduler.flowName] },
    workspace
  );
};
