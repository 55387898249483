import { AceApiOperation } from "@sapiens-digital/ace-designer-common";
import { Workspace } from "model/workspace";
import { initApiReferences } from "services/apis";
import {
  initFlowReferences,
  REFERENCES_INITIALIZED_EVENT_NAME,
} from "services/flows";
import { initSchemaReferences } from "services/schemas";

export type IndexReferencesDependencies = {
  flowPaths: string[];
  schemaPaths: string[];
  operations: AceApiOperation[];
};

export async function indexReferences(
  dependencies: IndexReferencesDependencies,
  workspace: Workspace
): Promise<void> {
  const { flowPaths, schemaPaths, operations } = dependencies;

  try {
    await initFlowReferences(flowPaths, workspace);
    initApiReferences(operations, workspace);
    await initSchemaReferences(schemaPaths, workspace);
  } catch (e) {
    console.warn("Failed to index", e);
  }

  dispatchEvent(new CustomEvent(REFERENCES_INITIALIZED_EVENT_NAME));
}
