import { STEPS_DEFAULT_OVERRIDE_CONFIG_MAP } from "./stepConstants";
import get from "lodash/get";
import set from "lodash/set";

export type StepToUpdate = {
  stepType: string;
  config?: { [key: string]: unknown };
  [extraProps: string]: unknown;
};

export default function updateStepConfigWithCustomDefault(
  step: StepToUpdate,
  stepsOverrideConfigMap = STEPS_DEFAULT_OVERRIDE_CONFIG_MAP
): void {
  const definitions = stepsOverrideConfigMap[step.stepType];

  definitions?.forEach((definition) => {
    if (get(step, definition.key) === definition.valueToCompare) {
      set(step, definition.key, definition.valueToOverride);
    }
  });
}
