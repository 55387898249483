import isEqual from "lodash/isEqual";

import { SchemaNode } from "./DiffableOperation";

export enum SchemaDifferenceType {
  Added = "Added",
  Changed = "Changed",
  Unchanged = "Unchanged",
}

export type SchemaDifference = {
  oldNameId: string;
  newNameId: string;
  diffStatus: SchemaDifferenceType;
};

export type NameSimilarity = {
  addedNameIds: string[];
  removedNameIds: string[];
  existingNameIds: string[];
};

export type TargetNodes = {
  oldIds: string[];
  newIds: string[];
};

export const splitNodesByName = ({
  oldIds,
  newIds,
}: TargetNodes): NameSimilarity => {
  const addedChilds: string[] = [];
  const removedChilds: string[] = [];
  const existingChilds: string[] = [];

  const touchedIds: string[] = [];
  oldIds.forEach((nameId) => {
    touchedIds.push(nameId);

    if (newIds.find((newChildId) => newChildId === nameId)) {
      existingChilds.push(nameId);
    } else {
      removedChilds.push(nameId);
    }
  });

  addedChilds.push(...newIds.filter((nameId) => !touchedIds.includes(nameId)));

  return {
    addedNameIds: addedChilds,
    removedNameIds: removedChilds,
    existingNameIds: existingChilds,
  };
};

type ContentSimilarity = {
  differentContentIds: string[];
  equalContentIds: string[];
};

const splitSchemasByContent = (
  { oldSchemas, newSchemas }: TargetSchemas,
  existingNameIds: string[]
): ContentSimilarity => {
  const result: ContentSimilarity = {
    differentContentIds: [],
    equalContentIds: [],
  };

  existingNameIds.forEach((id) => {
    const oldChild = oldSchemas.find((s) => s.nameId === id);
    const newChild = newSchemas.find((s) => s.nameId === id);

    if (isEqual(oldChild, newChild)) {
      result.equalContentIds.push(id);
    } else {
      result.differentContentIds.push(id);
    }
  });

  return result;
};

const diffAddedSchemas = (splitByName: NameSimilarity): SchemaDifference[] => {
  const { addedNameIds } = splitByName;

  const diffs: SchemaDifference[] = [];

  addedNameIds.forEach((newNameId) => {
    diffs.push({
      diffStatus: SchemaDifferenceType.Added,
      oldNameId: "",
      newNameId,
    });
  });

  return diffs;
};

const diffExistingSchemas = (
  targets: TargetSchemas,
  splitByContent: ContentSimilarity
): SchemaDifference[] => {
  const { oldSchemas, newSchemas } = targets;
  const { equalContentIds, differentContentIds } = splitByContent;

  const diffs: SchemaDifference[] = [];

  differentContentIds.forEach((id) => {
    diffs.push({
      diffStatus: SchemaDifferenceType.Changed,
      oldNameId: id,
      newNameId: id,
    });
  });

  equalContentIds.forEach((id) => {
    const oldChild = oldSchemas.find((s) => s.nameId === id);
    const newChild = newSchemas.find((s) => s.nameId === id);

    if (!oldChild || !newChild) {
      throw new Error(
        `Could not find schema '${id}' in old and new API for performing diff.`
      );
    }

    const areChildsSame = isEqual(
      oldChild.childNameIds.sort(),
      newChild.childNameIds.sort()
    );
    const areChildsUnchanged = !newChild.childNameIds.some((id) =>
      differentContentIds.includes(id)
    );

    const diffStatus =
      areChildsSame && areChildsUnchanged
        ? SchemaDifferenceType.Unchanged
        : SchemaDifferenceType.Changed;

    diffs.push({
      diffStatus,
      oldNameId: oldChild.nameId,
      newNameId: newChild.nameId,
    });
  });

  return diffs;
};

export type TargetSchemas = {
  oldSchemas: SchemaNode[];
  newSchemas: SchemaNode[];
};

export const getSchemaDifferences = (
  targets: TargetSchemas
): SchemaDifference[] => {
  const diffs: SchemaDifference[] = [];

  const splitByName = splitNodesByName({
    oldIds: targets.oldSchemas.map((s) => s.nameId),
    newIds: targets.newSchemas.map((s) => s.nameId),
  });
  const splitByContent = splitSchemasByContent(
    targets,
    splitByName.existingNameIds
  );

  diffs.push(...diffAddedSchemas(splitByName));
  diffs.push(...diffExistingSchemas(targets, splitByContent));

  return diffs;
};
