import { StepSchema } from "../../model/stepsSchemasState";

export type StepSchemaAdapter = (step: StepSchema) => void;

const adaptStepSelectionWidget: StepSchemaAdapter = (step) => {
  step.uiSchema.stepType = { "ui:widget": "changeStepTypeWidget" };
};

const adaptDefaultName: StepSchemaAdapter = (step) => {
  const properties = step.jsonSchema.properties;

  if (properties.name) {
    const nameWithDefaultValue = {
      ...properties.name,
      default: `Step ${step.stepType}`,
    };
    properties.name = nameWithDefaultValue;
  }
};

const adaptDefaultDescription: StepSchemaAdapter = (step) => {
  const properties = step.jsonSchema.properties;

  if (properties.description) {
    // (RJSF bug) missing/undefined value for textarea widget will cause undo to misbehave
    const descriptionWithDefaultValue = {
      ...properties.description,
      default: "",
    };
    properties.description = descriptionWithDefaultValue;
  }
};

export const adaptSchema: StepSchemaAdapter = (step) => {
  try {
    adaptStepSelectionWidget(step);
    adaptDefaultName(step);
    adaptDefaultDescription(step);
  } catch (err) {
    console.error(`Could not enrich steps schema (${step.stepType}) - ${err}`);
  }
};
