import { createSelector } from "@reduxjs/toolkit";

import { Tabbable, tabbableEntityConfig } from "../../model/workspace.tabs";
import { RootState } from "../index";
import { selectSelectedWorkspace } from "../workspaces/selectors";

export const makeSelectSelectedTabbableId = (
  tabbable: Tabbable
): ((state: RootState) => string | undefined) =>
  createSelector(selectSelectedWorkspace, (workspace) => {
    const { selectedIdKey } = tabbableEntityConfig[tabbable];
    return workspace?.[selectedIdKey];
  });
export const makeSelectOpenTabbableIds = (
  tabbable: Tabbable
): ((state: RootState) => string[]) =>
  createSelector(selectSelectedWorkspace, (workspace) => {
    const { openIdsKey } = tabbableEntityConfig[tabbable];
    return workspace?.[openIdsKey] || [];
  });
