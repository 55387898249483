import { OpenAPIV3 } from "openapi-types";

import { SerializedStep, SerializedFlow } from "../../model";
import { EntityFile } from "../../model/entityFile";

type ParametersData = {
  queryParams: Record<string, string>;
  sampleData: Record<string, string>;
  restStepHeaders: Record<string, string>;
};

export default class FlowGeneratorUtils {
  static getRestStep = (
    info: OpenAPIV3.InfoObject,
    path: string,
    verb: string,
    summary: string
  ): SerializedStep => {
    const step = {
      name: "REST",
      stepType: "rest-new",
      condition: "",
      config: {
        endpoint: {
          url: `{{$env.${info.title
            .replace(/ /g, "_")
            .toUpperCase()}_URL}}${path
            .replace(/{/g, "{{")
            .replace(/}/g, "}}")}`,
          method: verb.toUpperCase(),
        },
        restRequest: "JSON",
        json: {},
        queryParams: {},
        headers: {},
        targetPath: "result",
      },
      description: `${summary || ""}`,
    };

    return step;
  };

  static getAjvStep = (properties: unknown): SerializedStep => {
    const ajv = {
      name: "AJV",
      stepType: "ajv",
      condition: "",
      config: {
        dataPath: "requestBody",
        schema: properties,
        targetPath: "validations",
        error: true,
      },
      description: "validating request body",
    };

    return ajv;
  };

  static getJsonataStepForQueryParam = (
    queryParams: Record<string, string>
  ): SerializedStep => {
    const jsonata = {
      name: "JSONATA MAP",
      stepType: "jsonata",
      condition: "",
      config: {
        maps: [
          {
            jsonata: JSON.stringify(queryParams).replaceAll('\\"', ""),
            targetPath: "queryParams",
          },
        ],
      },
      description: "mapping query params",
    };

    return jsonata;
  };

  static getJsonataStepForRequestBody = (): SerializedStep => {
    const jsonata = {
      name: "JSONATA MAP",
      stepType: "jsonata",
      condition: "",
      config: {
        maps: [
          {
            jsonata: "$",
            targetPath: "requestBody",
          },
        ],
      },
      description: "mapping request body",
    };
    return jsonata;
  };

  static getFlowName = (path: string, verb: string): string =>
    `${path
      .replace("/", "")
      .replaceAll("/", "-")
      .replaceAll("{", "")
      .replaceAll("}", "")}-${verb}`;

  static extractParametersForSteps = (
    parameters: OpenAPIV3.ParameterObject[]
  ): ParametersData => {
    const result: ParametersData = {
      queryParams: {},
      sampleData: {},
      restStepHeaders: {},
    };
    if (parameters?.length) {
      parameters.forEach((p) => {
        const param = (p as unknown) as OpenAPIV3.ParameterObject;
        const paramSchema = param?.schema as OpenAPIV3.SchemaObject;
        if (param.in === "query") {
          result.queryParams[param.name] = `"${param.name}"`;
          result.sampleData[param.name] = `${paramSchema?.type || ""}`;
        }
        if (param.in === "path") {
          result.sampleData[param.name] = `${paramSchema?.type || ""}`;
        }
        if (param.in === "header") {
          result.restStepHeaders[param.name] = `{{$headers.${param.name}}}`;
        }
      });
    }
    return result;
  };

  static getSecurityHeadersForRestStep = (
    components: OpenAPIV3.ComponentsObject,
    security: OpenAPIV3.SecurityRequirementObject[]
  ): Record<string, string> => {
    const headers: Record<string, string> = {};
    if (security?.length) {
      security.forEach((securityObj) => {
        const name = Object.keys(securityObj)?.[0];
        if (components && components.securitySchemes) {
          const securitySchemes = components.securitySchemes[name];
          if ("name" in securitySchemes) {
            const scheme: OpenAPIV3.ApiKeySecurityScheme = (securitySchemes as unknown) as OpenAPIV3.ApiKeySecurityScheme;
            headers[scheme.name] = `{{$env.${scheme.name}}}`;
          }
        }
      });
    }

    return headers;
  };

  static extractRequestBodySchema = (
    components: OpenAPIV3.ComponentsObject,
    requestBody: OpenAPIV3.RequestBodyObject
  ): unknown => {
    let requestBodySchema = requestBody?.content?.["application/json"]?.schema;

    if (requestBodySchema && "$ref" in requestBodySchema) {
      const fullRefPath = requestBodySchema.$ref.split("/");
      const schemaName = fullRefPath.pop();
      if (components && components.schemas && schemaName) {
        requestBodySchema = components.schemas[schemaName];
      }
    }
    return requestBodySchema;
  };

  static getNewFlow = (
    path: string,
    verb: string,
    tags: string[] | undefined,
    operationId?: string
  ): EntityFile<SerializedFlow> => ({
    name: operationId || FlowGeneratorUtils.getFlowName(path, verb),
    content: {
      description: "Created by imported openApi schema",
      tags: tags || [],
      steps: [],
      sampleInputSchema: "",
      sampleData: {},
    },
  });
}
