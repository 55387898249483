import { Typography } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import { styled } from "@mui/material/styles";

import { useAppSelector } from "../store";
import { selectIsDesignerIndexing } from "../store/designer/selectors";

const Root = styled("div")(() => ({
  display: "flex",
  height: "20px",
}));

const IndexingLoader: React.FC = () => {
  const isIndexing = useAppSelector(selectIsDesignerIndexing);

  if (isIndexing !== true) {
    return null;
  }

  return (
    <Root>
      <Typography
        sx={{ fontSize: "12px", margin: "auto", paddingRight: "5px" }}
      >
        Indexing
      </Typography>
      <CircularProgress
        sx={{ color: "white", marginTop: "3px" }}
        size={12}
        data-testid="indexing-loading"
      />
    </Root>
  );
};

export default IndexingLoader;
