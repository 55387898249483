import { createSlice } from "@reduxjs/toolkit";

import {
  createWorkspaceAction,
  deleteLocalWorkspacesAction,
  deleteWorkspaceAction,
  loadWorkspacesAction,
  openWorkspaceAction,
  refreshWorkspaceAction,
} from "./actions";
import { loadWorkspaceAction } from "./loadWorkspaceAction";
import { workspacesAdapter } from "./selectors";
import { updateWorkspaceAction } from "./updateWorkspaceAction";

const initialState = workspacesAdapter.getInitialState();

const workspacesSlice = createSlice({
  name: "workspaces",
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(loadWorkspacesAction.fulfilled, (state, action) => {
      workspacesAdapter.removeAll(state);
      workspacesAdapter.upsertMany(state, action.payload);
    });
    builder.addCase(updateWorkspaceAction.fulfilled, (state, action) => {
      workspacesAdapter.upsertOne(state, action.payload);
    });
    builder.addCase(loadWorkspaceAction.fulfilled, (state, action) => {
      workspacesAdapter.upsertOne(state, action.payload);
    });
    builder.addCase(refreshWorkspaceAction.fulfilled, (state, action) => {
      workspacesAdapter.upsertOne(state, action.payload);
    });
    builder.addCase(createWorkspaceAction.fulfilled, (state, action) =>
      workspacesAdapter.upsertOne(state, action.payload)
    );
    builder.addCase(openWorkspaceAction.fulfilled, (state, action) =>
      workspacesAdapter.upsertOne(state, action.payload)
    );
    builder.addCase(deleteWorkspaceAction.fulfilled, (state, action) =>
      workspacesAdapter.removeOne(state, action.payload)
    );
    builder.addCase(deleteLocalWorkspacesAction.fulfilled, (state) =>
      workspacesAdapter.removeAll(state)
    );
  },
});

export default workspacesSlice.reducer;
