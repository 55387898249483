import { REGX_FOR_VALID_STRING_EXPRESSION, Steps, StepSchema } from "../model";

export const lookupStepSchema: StepSchema = {
  stepType: Steps.LOOKUP,
  color: "rgb(223,204,241)",
  jsonSchema: {
    description: "Replace value from data placed in lookup lists",
    title: "Lookup Map",
    type: "object",
    properties: {
      name: {
        type: "string",
        title: "Name",
      },
      stepType: {
        type: "string",
        title: "stepType",
      },
      condition: {
        type: "string",
        title:
          "An optional condition on the step. If returns false step will be skipped (e.g {{input.age>10}})",
        pattern: REGX_FOR_VALID_STRING_EXPRESSION,
      },
      config: {
        type: "object",
        title: "Configuration",
        properties: {
          maps: {
            type: "array",
            title: "Lookup maps definitions",
            items: {
              type: "object",
              properties: {
                targetPath: {
                  type: "string",
                  title:
                    "Target path - target location of the source data inside the document",
                },
                searchByPathValue: {
                  type: "string",
                  title:
                    "Search By Path- path in document to extract the value which will be  searched as key in lookup",
                },
                searchByValue: {
                  type: "string",
                  title:
                    "Search by value - the value which will be searched as key in lookup (required if 'searchByPathValue' is not defined)",
                },
                lookup: {
                  type: "object",
                  title: "Lookup properties",
                  properties: {
                    sourcePath: {
                      type: "string",
                      title:
                        "Source path - path in document where the lookup array is located under",
                    },
                    sourceLookup: {
                      type: ["array", "string", "null"],
                      pattern: REGX_FOR_VALID_STRING_EXPRESSION,
                      title:
                        "Source Lookup - inline lookup array (required if 'sourcePath' is not defined)",
                      items: {
                        type: "object",
                      },
                      default: null,
                    },
                    lookupKeyPath: {
                      type: "string",
                      title:
                        "Key path - the path inside a single lookup item for containing the items key",
                    },
                    lookupValuePath: {
                      type: "string",
                      title:
                        "Value path - the path inside a single lookup item for containing the items value. If left empty the entire item will be returned",
                    },
                  },
                },
              },
            },
          },
        },
      },
    },
  },
  uiSchema: {
    stepType: {
      "ui:widget": "hidden",
    },
    name: {
      classNames: "hidden-ace4",
    },
    description: {
      "ui:widget": "textarea",
    },
    config: {
      maps: {
        items: {
          lookup: {
            sourceLookup: {
              "ui:field": "json",
            },
          },
        },
      },
    },
  },
};
