import React from "react";
import { Stack } from "@mui/material";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";

import { Exporters } from "./exporters/Exporters";
import WorkspaceSelector from "./workspace/WorkspaceSelector";
import IndexingLoader from "./IndexingLoader";
import Version from "./Version";

export const BOTTOM_BAR_HEIGHT = 20;

const BottomBar: React.FC = () => (
  <AppBar
    elevation={0}
    position="relative"
    sx={{
      backgroundColor: "brand1",
    }}
  >
    <Toolbar
      sx={{
        display: "flex",
        minHeight: { xs: `${BOTTOM_BAR_HEIGHT}px` },
        justifyContent: "space-between",
      }}
    >
      <WorkspaceSelector />
      <Stack direction="row" spacing={3}>
        <IndexingLoader />
        <Exporters />
        <Version />
      </Stack>
    </Toolbar>
  </AppBar>
);

export default BottomBar;
