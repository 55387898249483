import get from "lodash/get";
import { Pair } from "yaml";

export const createSortYamlMapEntries = (keysInOrderOfPriority: string[]) => (
  a: Pair,
  b: Pair
): 1 | 0 | -1 => {
  const aValue = get(a.key, "value");
  const bValue = get(b.key, "value");
  if (!(typeof aValue === "string" && typeof bValue === "string")) return 0;
  const aIdx = keysInOrderOfPriority.indexOf(aValue);
  const bIdx = keysInOrderOfPriority.indexOf(bValue);

  const aPrio = aIdx !== -1 ? keysInOrderOfPriority.length - aIdx : aIdx;
  const bPrio = bIdx !== -1 ? keysInOrderOfPriority.length - bIdx : bIdx;

  if (aPrio === bPrio) return 0;
  if (aPrio < bPrio) return 1;
  return -1;
};
