import React from "react";
import { useSelector } from "react-redux";
import { styled } from "@mui/material/styles";
import ErrorOutlineSharpIcon from "@mui/icons-material/ErrorOutlineSharp";
import Typography from "@mui/material/Typography";

import { RootState } from "../store";

const Root = styled("div")(() => ({
  display: "flex",
  height: "20px",
}));

const Version: React.FC = () => {
  const version = useSelector<RootState, string>(
    (state) => state.designer.version
  );

  return (
    <Root>
      <ErrorOutlineSharpIcon
        sx={{ height: "16px", width: "16px", margin: "auto" }}
      />
      <Typography sx={{ fontSize: "12px", margin: "auto", paddingLeft: "5px" }}>
        ACE {version}
      </Typography>
    </Root>
  );
};

export default Version;
