import { REGX_FOR_VALID_STRING_EXPRESSION, Steps, StepSchema } from "../model";

export const restDeprecatedStepSchema: StepSchema = {
  stepType: Steps.REST,
  color: "rgb(247,225,211)",
  jsonSchema: {
    description: "REST API step definition",
    title: "REST Http (Deprecated)",
    type: "object",
    properties: {
      name: {
        type: "string",
        title: "Name",
      },
      stepType: {
        type: "string",
        title: "stepType",
      },
      description: {
        type: "string",
        title: "Step Description",
      },
      condition: {
        type: "string",
        title:
          "An optional condition on the step. If returns false step will be skipped (e.g {{input.age>10}})",
        pattern: REGX_FOR_VALID_STRING_EXPRESSION,
      },
      config: {
        type: "object",
        title: "Configuration",
        required: ["endpoint", "targetPath"],
        properties: {
          endpoint: {
            type: "object",
            title: "End Point",
            required: ["url"],
            properties: {
              url: {
                type: "string",
                title: "Url - REST request url",
              },
              method: {
                type: "string",
                title: "Method - the rest method type (Default is GET)",
                enum: ["GET", "POST", "PUT", "DELETE", "PATCH"],
              },
            },
          },
          targetPath: {
            type: "string",
            title:
              "Target path - define the path in which to store the result returned when executing the api",
          },
          headerPath: {
            type: "string",
            title:
              "Define the path in which to store request/response headers of the api call. " +
              "No header values will be stored if left blank.",
          },
          cacheble: {
            type: "boolean",
            title: "Store target path results in cache",
          },
          body: {
            type: ["object", "null"],
            title: "the body to send in the rest request",
          },
          queryParams: {
            type: ["object", "null"],
            title: "the query params to send in the rest request",
          },
          headers: {
            type: ["object", "null"],
            title: "The HTTP headers to send in the rest request",
          },
        },
        dependencies: {
          cacheble: {
            oneOf: [
              {
                properties: {
                  cacheble: {
                    enum: [true],
                  },
                  disableHeadersInKey: {
                    type: "boolean",
                    title:
                      "Disable the usage of the HTTP headers in the cache key of the results",
                    enum: [true, false],
                    default: true,
                  },
                  ttl: {
                    type: "number",
                    title: "TTL cache (minutes)",
                  },
                },
              },
              {
                properties: {
                  cacheble: {
                    enum: [false],
                  },
                },
              },
            ],
          },
        },
      },
    },
  },
  uiSchema: {
    stepType: {
      classNames: "hidden",
    },
    description: {
      "ui:widget": "textarea",
    },
    config: {
      "ui:order": [
        "endpoint",
        "targetPath",
        "headerPath",
        "cacheble",
        "disableHeadersInKey",
        "ttl",
        "*",
      ],
      body: {
        "ui:field": "json",
      },
      queryParams: {
        "ui:field": "json",
      },
      headers: {
        "ui:field": "json",
      },
    },
  },
};
