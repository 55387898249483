import get from "lodash/get";

export function readError(
  error: unknown,
  tryKey = "message",
  transformationMap?: Record<string, string>
): string {
  const value = get(error, tryKey);

  if (value) {
    if (transformationMap && transformationMap[value]) {
      return transformationMap[value];
    }

    return value;
  }

  return String(error);
}
