import { WorkspaceSettings } from "../../model/workspace";
import { container, symbols } from "..";

export type LoadWorkspaceSettings = () => Promise<WorkspaceSettings>;
export type SaveWorkspaceSettings = (
  settings: WorkspaceSettings
) => Promise<void>;

export const loadWorkspaceSettings: LoadWorkspaceSettings = async () =>
  await container.get<LoadWorkspaceSettings>(symbols.LoadWorkspaceSettings)();
export const saveWorkspaceSettings: SaveWorkspaceSettings = async (
  settings: WorkspaceSettings
) =>
  await container.get<SaveWorkspaceSettings>(symbols.SaveWorkspaceSettings)(
    settings
  );
