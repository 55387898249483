import { AppEventParams } from "../model/appEvents";

import { container, symbols } from ".";

export interface EmitAppEvent {
  (...event: AppEventParams): void;
}

export const emitAppEvent: EmitAppEvent = (...event) => {
  container.get<EmitAppEvent>(symbols.EmitAppEvent)(...event);
};
