import toJsonSchema from "to-json-schema";
import { OpenAPIV3 } from "openapi-types";

export function generateSchemaFromJson(json: unknown): OpenAPIV3.SchemaObject {
  return (toJsonSchema(json, {
    strings: {
      detectFormat: false,
    },
    objects: {
      preProcessFnc: (value, defaultFnc) => {
        const schema = defaultFnc(value);
        schema.example = value;
        return schema;
      },
    },
    postProcessFnc: (type, schema, value, defaultFunc) => {
      if (type === "null") {
        return { ...schema, ...{ type: "string" } };
      }
      if (type === "array" && !schema.items) {
        return { ...schema, ...{ items: { type: "object" } } };
      }
      return defaultFunc(type, schema, value);
    },
  }) as unknown) as OpenAPIV3.SchemaObject;
}
