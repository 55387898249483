import { REGX_FOR_VALID_STRING_EXPRESSION, Steps, StepSchema } from "../model";

export const redisStepSchema: StepSchema = {
  stepType: Steps.REDIS,
  color: "rgb(95, 206, 165)",
  jsonSchema: {
    description: "Connection to redis",
    title: "Redis",
    type: "object",
    required: ["connectionString"],
    properties: {
      name: {
        type: "string",
        title: "Name",
      },
      stepType: {
        type: "string",
        title: "stepType",
      },
      description: {
        type: "string",
        title: "Step Description",
      },
      condition: {
        type: "string",
        title:
          "An optional condition on the step. If returns false step will be skipped (e.g {{input.age>10}})",
        pattern: REGX_FOR_VALID_STRING_EXPRESSION,
      },
      connectionString: {
        title:
          "Connection String example: redis[s]://[[username][:password]@][host][:port][/db-number]",
        type: "string",
      },
      username: {
        title: "User Name",
        type: "string",
      },
      password: {
        title: "Password",
        type: "string",
      },
      useTls: {
        type: "boolean",
        title: "Use TLS",
        default: false,
        enum: [true, false],
      },
      config: {
        type: "object",
        title: "Configuration",
        required: [],
        properties: {
          actions: {
            type: "array",
            title: "Actions",
            items: {
              type: "object",
              required: ["target", "action"],
              properties: {
                action: {
                  title: "Action",
                  type: "string",
                  enum: ["get", "set", "delete", "custom"],
                  default: "get",
                },
                target: {
                  title:
                    "Target path - target location of the source data inside the document",
                  type: "string",
                },
              },
              dependencies: {
                action: {
                  oneOf: [
                    {
                      properties: {
                        action: {
                          enum: ["get"],
                        },
                        key: {
                          type: "string",
                          title: "Key",
                        },
                        doJsonParse: {
                          type: "boolean",
                          title: "JSON Parse returned value",
                          default: true,
                        },
                        options: {
                          type: "object",
                          title: "Options",
                        },
                      },
                    },
                    {
                      properties: {
                        action: {
                          enum: ["set"],
                        },
                        key: {
                          type: "string",
                          title: "Key",
                        },
                        value: {
                          type: [
                            "object",
                            "string",
                            "number",
                            "boolean",
                            "array",
                          ],
                          title: "Value",
                        },
                        doJsonStringify: {
                          type: "boolean",
                          title: "JSON Stringify value",
                          default: true,
                        },
                        options: {
                          type: "object",
                          title: "Options",
                        },
                      },
                    },
                    {
                      properties: {
                        action: {
                          enum: ["delete"],
                        },
                        key: {
                          type: "string",
                          title: "Key",
                        },
                        options: {
                          type: "object",
                          title: "Options",
                        },
                      },
                    },
                    {
                      properties: {
                        action: {
                          enum: ["custom"],
                        },
                        payload: {
                          type: "array",
                          title: "Payload",
                        },
                        options: {
                          type: "object",
                          title: "Options",
                        },
                      },
                    },
                  ],
                },
              },
            },
          },
        },
      },
    },
    dependencies: {
      useTls: {
        oneOf: [
          {
            properties: {
              useTls: {
                enum: [true],
              },
              tlsKey: {
                type: "string",
                title: "TLS Key",
              },
              tlsCert: {
                type: "string",
                title: "TLS Cert",
              },
              tlsCa: {
                type: "string",
                title: "TLS CA",
              },
            },
          },
          {
            properties: {
              cacheble: {
                enum: [false],
              },
            },
          },
        ],
      },
    },
  },
  uiSchema: {
    "ui:order": ["*", "useTls", "tlsKey", "tlsCert", "tlsCa", "config"],
    stepType: {
      "ui:widget": "hidden",
    },
    name: {
      classNames: "hidden-ace4",
    },
    description: {
      "ui:widget": "textarea",
    },
    config: {
      actions: {
        items: {
          options: {
            "ui:field": "json",
          },
          payload: {
            "ui:field": "json",
          },
          value: {
            "ui:field": "json",
          },
        },
      },
    },
  },
};
