import path from "path";
import { v4 } from "uuid";

import { mkdir, rmdirDeep } from "./fs-utils";

const TEMP_FOLDER = ".temp";

export const createTemporaryFolder = async (
  workspacesLocation: string
): Promise<string> => {
  const folderName = v4();
  const absFolderPath = path.join(workspacesLocation, TEMP_FOLDER, folderName);
  await mkdir(absFolderPath);

  return absFolderPath;
};

export const resetTemporaryFiles = async (
  workspacesLocation: string
): Promise<void> => {
  const temporaryFilesPath = path.join(workspacesLocation, TEMP_FOLDER);
  await mkdir(temporaryFilesPath);
  await rmdirDeep(temporaryFilesPath, { skipLocationRoot: true });
};
