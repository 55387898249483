import { Schedule } from "../../model/schedules";
import { createRedoableSlice } from "../utils/redoableSliceFactory";

import {
  deleteScheduleAction,
  openScheduleAction,
  refreshSchedulesAction,
  storeScheduleAction,
} from "./actions";
import { name } from "./sliceName";

const schedulesSlice = createRedoableSlice<Schedule>({
  name,
  reducers: {},
  predefinedThunks: {
    openEntityAction: openScheduleAction,
    storeEntityAction: storeScheduleAction,
    deleteEntityAction: deleteScheduleAction,
    refreshEntitiesAction: refreshSchedulesAction,
  },
});

export const { update, add, remove, redo, undo } = schedulesSlice.actions;

export default schedulesSlice.reducer;
