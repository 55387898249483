import { REGX_FOR_VALID_STRING_EXPRESSION, Steps, StepSchema } from "../model";

export const sapiqDbStepSchema: StepSchema = {
  stepType: Steps.SAPIQDB,
  color: "rgb(95, 206, 165)",
  jsonSchema: {
    description: "Connection to the SAP IQ data base",
    title: "SAP IQ DB",
    type: "object",
    properties: {
      name: {
        type: "string",
        title: "Name",
      },
      stepType: {
        type: "string",
        title: "stepType",
      },
      description: {
        type: "string",
        title: "Step Description",
      },
      condition: {
        type: "string",
        title:
          "An optional condition on the step. If returns false step will be skipped (e.g {{input.age>10}})",
        pattern: REGX_FOR_VALID_STRING_EXPRESSION,
      },
      config: {
        type: "object",
        title: "Configuration",
        required: ["host", "port", "dbname", "username", "password", "actions"],
        properties: {
          host: {
            title: "Host",
            type: "string",
          },
          port: {
            title: "Port",
            type: "number",
          },
          dbname: {
            title: "Data base name",
            type: "string",
          },
          username: {
            title: "User Name",
            type: "string",
          },
          password: {
            title: "Password",
            type: "string",
          },
          actions: {
            type: "array",
            title: "Actions",
            default: [
              {
                sql: "",
              },
            ],
            items: {
              type: "object",
              required: ["sql"],
              properties: {
                sql: {
                  title: "SQL",
                  type: "string",
                },
                targetPath: {
                  title:
                    "Target path - target location of the source data inside the document",
                  type: "string",
                },
              },
            },
          },
        },
      },
    },
  },
  uiSchema: {
    stepType: {
      "ui:widget": "hidden",
    },
    name: {
      classNames: "hidden-ace4",
    },
    description: {
      "ui:widget": "textarea",
    },
    config: {
      actions: {
        items: {
          sql: {
            "ui:field": "json",
          },
        },
      },
    },
  },
};
