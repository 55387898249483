import { isFulfilled, isRejected, isRejectedWithValue } from "@reduxjs/toolkit";
import { UnknownAsyncThunkAction } from "@reduxjs/toolkit/src/matchers";

import { AppDispatch } from "../../store";
import { addNotification } from "../../store/designer/actions";
import { isUnauthorized } from "../../store/utils/executeFlowHelper";

/**
 * Makes a notification from the result of the async thunk action
 *
 * @example
 * const result = await dispatch(resetWorkspaceToRemoteAction());
 * notify(result, dispatch, "Workspace was reset to the remote version");
 *
 * @param result dispatched async thunk (generated by `createAsyncThunk`)
 * @param dispatch
 * @param successText
 * @param errorTextFallback displayed in error notification when rejected action neither
 * has a payload of type string nor an error message.
 */
export function notify(
  result: UnknownAsyncThunkAction,
  dispatch: AppDispatch,
  successText: string,
  errorTextFallback = "Error happened during the execution"
): void {
  if (isFulfilled(result)) {
    dispatch(addNotification({ message: successText, variant: "success" }));
    return;
  }

  notifyError(result, dispatch, errorTextFallback);
}

/**
 * Makes a notification from the result of the async thunk action if action is rejected
 *
 * @example
 * const result = await dispatch(resetWorkspaceToRemoteAction());
 * notifyError(result, dispatch);
 *
 * @param result dispatched async thunk (generated by `createAsyncThunk`)
 * @param dispatch
 * @param errorTextFallback displayed in error notification when rejected action neither
 * has a payload of type string nor an error message.
 */
export function notifyError(
  result: UnknownAsyncThunkAction,
  dispatch: AppDispatch,
  errorTextFallback = "Error happened during the execution"
): void {
  if (isRejectedWithValue(result)) {
    const message =
      typeof result.payload === "string" ? result.payload : errorTextFallback;
    dispatch(
      addNotification({
        message,
        variant: "error",
      })
    );
    return;
  }

  if (isRejected(result)) {
    dispatch(
      addNotification({
        message: result.error.message || errorTextFallback,
        variant: "error",
      })
    );
  }
}

export const notifyNonAuthError = (
  result: UnknownAsyncThunkAction,
  dispatch: AppDispatch
): void => {
  if (!isUnauthorized(result)) {
    notifyError(result, dispatch);
  }
};
