import { REGX_FOR_VALID_STRING_EXPRESSION, Steps, StepSchema } from "../model";

export const xmlStepSchema: StepSchema = {
  stepType: Steps.XML,
  color: "rgb(204,229,255)",
  jsonSchema: {
    title: "XML ⇄ JSON",
    description: "XML to JSON, JSON to XML",
    type: "object",
    properties: {
      name: {
        type: "string",
        title: "Name",
      },
      stepType: {
        type: "string",
        title: "stepType",
      },
      description: {
        type: "string",
        title: "Step Description",
      },
      condition: {
        type: "string",
        title:
          "An optional condition on the step. If returns false step will be skipped (e.g {{input.age>10}})",
        pattern: REGX_FOR_VALID_STRING_EXPRESSION,
      },
      config: {
        type: "object",
        title: "Configuration",
        required: ["xml", "targetPath", "attrKey"],
        properties: {
          mode: {
            title: "Mode",
            type: "string",
            enum: ["XML to JSON", "JSON to XML"],
            default: "XML to JSON",
          },
          attrKey: {
            title: "Attribute key",
            type: "string",
            default: "$",
          },
          targetPath: {
            type: "string",
            title: "Target path - define the path in which to store the result",
          },
        },
        dependencies: {
          mode: {
            oneOf: [
              {
                properties: {
                  mode: {
                    enum: ["XML to JSON"],
                  },
                  explicitArray: {
                    title: "Put child nodes in an array",
                    type: "boolean",
                    default: true,
                  },
                  xml: {
                    type: "string",
                    title: "XML path",
                    minLength: 1,
                  },
                },
              },
              {
                properties: {
                  mode: {
                    enum: ["JSON to XML"],
                  },
                  headlessXml: {
                    title: "Headless xml",
                    type: "boolean",
                    default: true,
                  },
                  json: {
                    type: "string",
                    title: "JSON path",
                    minLength: 1,
                  },
                },
              },
            ],
          },
        },
      },
    },
  },
  uiSchema: {
    stepType: {
      "ui:widget": "hidden",
    },
    name: {
      classNames: "hidden-ace4",
    },
    description: {
      "ui:widget": "textarea",
    },
    config: {
      xml: {
        "ui:field": "xmlEditor",
      },
      json: {
        "ui:field": "json",
      },
    },
  },
};
