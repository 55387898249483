import { createSelector } from "@reduxjs/toolkit";

import { Workspace, WorkspaceFolder } from "../../model/workspace";
import { getDeserializedId } from "../../services/references";
import { getContentRoot } from "../../services/workspace";
import { selectSelectedWorkspace } from "../workspaces/selectors";
import { RootState } from "..";

export const selectEntityId = (
  path: string,
  folder: WorkspaceFolder
): ((state: RootState) => string | undefined) =>
  createSelector(
    selectSelectedWorkspace,
    (workspace: Workspace | undefined) =>
      (workspace &&
        path &&
        getDeserializedId(getContentRoot(workspace, folder), path)) ??
      undefined
  );
