import { Folder } from "../../model/file";

/**
 * Returns an object path to the `FileNode` from the specified `Folder`
 *
 * @example
 * getObjectPathToFileNode("folder/file.yaml", [
 *   { displayName: "folder", children: [{...}, { displayName: "file" }] },
 *   {...},
 * ]); // ["0", "folder", "1"]
 *
 * @param filePath
 * @param folder Folder (fs-like) with nested `FileNode`s
 */
export function getObjectPathToFileNode(
  filePath: string,
  folder: Folder
): string[] {
  const folders = filePath.split("/");
  const nameToSearch = folders[0];
  if (folders.length === 1) {
    const fileName = nameToSearch.replace(".yaml", "");
    const fileIdx = folder.findIndex((f) => f.displayName === fileName);
    if (fileIdx === -1) throw new Error(`File '${fileName}' not found`);

    return [String(fileIdx)];
  }

  const nestedFolderIdx = folder.findIndex(
    (f) => f.displayName === nameToSearch
  );
  if (nestedFolderIdx === -1)
    throw new Error(`Folder '${nameToSearch}' not found`);

  const nestedFolder = folder[nestedFolderIdx];
  const objectPathFromNestedFolder = getObjectPathToFileNode(
    folders.slice(1).join("/"),
    nestedFolder.children
  );

  return [String(nestedFolderIdx), "children", ...objectPathFromNestedFolder];
}
