import { REGX_FOR_VALID_STRING_EXPRESSION, Steps, StepSchema } from "../model";

export const jsonataStepSchema: StepSchema = {
  stepType: Steps.JSONATA,
  color: "rgb(245,210,211)",
  jsonSchema: {
    title: "JSONATA Map",
    description: "Map Enrich JSONATA step definition",
    type: "object",
    properties: {
      name: {
        type: "string",
        title: "Name",
      },
      stepType: {
        type: "string",
        title: "stepType",
      },
      stepArgs: {
        type: "string",
        title: "stepArgs",
      },
      description: {
        type: "string",
        title: "Step Description",
      },
      condition: {
        type: "string",
        title:
          "An optional condition on the step. If returns false step will be skipped (e.g {{input.age>10}})",
        pattern: REGX_FOR_VALID_STRING_EXPRESSION,
      },
      config: {
        type: "object",
        title: "Configuration",
        properties: {
          maps: {
            title: "Mappings",
            type: "array",
            minItems: 1,
            items: {
              type: "object",
              required: ["jsonata", "targetPath"],
              properties: {
                jsonata: {
                  type: "string",
                  minLength: 1,
                  title:
                    "Jsonta - a jsonata string for mapping from document data",
                },
                targetPath: {
                  type: "string",
                  title: "Target path - path for result",
                },
                sourcePath: {
                  type: "string",
                  title:
                    "Source path - an optional field that can be used as the base path for the jsonata content",
                },
                setNullSource: {
                  type: "boolean",
                  title:
                    "when enrich result is null - if set to true then map to target with null value. by default will not map null values",
                },
                append: {
                  type: "boolean",
                  title:
                    "Append - when set to true the result will be appended to the target path making the target path an array",
                },
                mergeArrayItems: {
                  type: "object",
                  title:
                    "Merge Array Items - an optional config to merge items in source and target arrays. The items of both arrays have to be in the same structure",
                  properties: {
                    mergeByKey: {
                      type: "string",
                      title: "find items to merge by this key",
                    },
                    excludeTargetItems: {
                      type: "string",
                      title:
                        "an optional jsonata expression to exclude items in the target when merging",
                    },
                  },
                },
              },
            },
          },
        },
      },
    },
  },
  uiSchema: {
    stepType: {
      "ui:widget": "hidden",
    },
    stepArgs: {
      "ui:widget": "hidden",
    },
    name: {
      classNames: "hidden-ace4",
    },
    description: {
      "ui:widget": "textarea",
    },
    config: {
      maps: {
        items: {
          jsonata: {
            "ui:field": "jsonata",
          },
        },
      },
    },
  },
};
