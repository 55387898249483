import { RootState } from "../index";

import { Entity, GenericState, Redoable } from "./redoableSliceFactory";

export const getRedoableSlice = <T extends Entity>(
  state: RootState,
  sliceName: string
): GenericState<T> =>
  (state[sliceName as keyof RootState] as unknown) as GenericState<T>;

export const selectEntity = <T extends Entity>(
  redoableSliceState: GenericState<T>,
  id: string
): Redoable<T> | undefined =>
  redoableSliceState.find((entity) => entity.present.id === id);
