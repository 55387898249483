import React from "react";
import { SvgIcon, SxProps } from "@mui/material";

interface WorkSpaceIconProps {
  sx?: SxProps;
}

export const WorkspaceIcon: React.FC<WorkSpaceIconProps> = (props) => {
  return (
    <SvgIcon sx={props.sx}>
      <path d="M6,13c-2.2,0-4,1.8-4,4s1.8,4,4,4s4-1.8,4-4S8.2,13,6,13z M12,3C9.8,3,8,4.8,8,7s1.8,4,4,4s4-1.8,4-4S14.2,3,12,3z M18,13 c-2.2,0-4,1.8-4,4s1.8,4,4,4s4-1.8,4-4S20.2,13,18,13z" />
    </SvgIcon>
  );
};

export default WorkspaceIcon;
