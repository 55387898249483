import {
  deleteErrorHandler,
  getErrorHandler,
  saveErrorHandler,
  serializeErrorHandler,
} from "../../services/error-handlers";
import {
  addEntityToGitActionCreator,
  deleteAndPushActionCreator,
  deleteEntityActionCreator,
  openEntityActionCreator,
  refreshEntitiesActionCreator,
  storeAndPushActionCreator,
  storeEntityActionCreator,
} from "../utils/actionFactory";

import { nameSelector } from "./selectors";
import { name } from "./sliceName";

export const openErrorHandlerAction = openEntityActionCreator(
  name,
  getErrorHandler
);
export const storeErrorHandlerAction = storeEntityActionCreator(
  name,
  saveErrorHandler,
  "errorHandlers"
);
export const deleteErrorHandlerAction = deleteEntityActionCreator(
  name,
  deleteErrorHandler,
  "errorHandlers"
);

export const addErrorHandlersToGitAction = addEntityToGitActionCreator(
  name,
  "errorHandlers"
);

export const storeAndPushErrorHandlerAction = storeAndPushActionCreator(
  name,
  nameSelector,
  storeErrorHandlerAction,
  addErrorHandlersToGitAction
);

export const deleteAndPushErrorHandlerAction = deleteAndPushActionCreator(
  name,
  deleteErrorHandlerAction,
  addErrorHandlersToGitAction
);

export const refreshErrorHandlersAction = refreshEntitiesActionCreator(
  name,
  getErrorHandler,
  serializeErrorHandler
);
