import { PayloadAction } from "@reduxjs/toolkit";
import YAML from "yaml";

import { Schema } from "../../model/schemas";
import { serializeSchema } from "../../services/schemas";
import { createRedoableSlice } from "../utils/redoableSliceFactory";
import { updateEntityWithDraft } from "../utils/redoableSliceMutators";

import {
  deleteSchemasAction,
  deleteSchemasFolderAction,
  openSchemasAction,
  overwriteSchemasFolderAction,
  refreshSchemaAction,
  storeSchemasAction,
  updateWithSchemaDraftAction,
} from "./actions";
import { name } from "./sliceName";

const schemasSlice = createRedoableSlice<Schema>({
  name,
  reducers: {},
  predefinedThunks: {
    refreshEntitiesAction: refreshSchemaAction,
    openEntityAction: openSchemasAction,
    storeEntityAction: storeSchemasAction,
    deleteEntityAction: deleteSchemasAction,
    deleteFolderAction: deleteSchemasFolderAction,
    overwriteFolderAction: overwriteSchemasFolderAction,
  },
  extraReducers: (builder) => {
    builder.addCase(
      updateWithSchemaDraftAction,
      (state, action: PayloadAction<Schema>) => {
        const schema = action.payload;
        const draftValue = YAML.stringify(serializeSchema(schema));

        return updateEntityWithDraft(state, schema, draftValue);
      }
    );
  },
});

export const {
  update,
  updateDraft,
  updateOmitHistory,
  add,
  addTargetFolder,
  remove,
  redo,
  undo,
} = schemasSlice.actions;

export default schemasSlice.reducer;
