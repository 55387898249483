import { REGX_FOR_VALID_STRING_EXPRESSION, Steps, StepSchema } from "../model";

export const SoapPureStepSchema: StepSchema = {
  stepType: Steps.SOAP_PURE,
  color: "rgb(247,225,211)",
  jsonSchema: {
    title: "SOAP HTTP (Pure)",
    type: "object",
    properties: {
      name: {
        type: "string",
        title: "Name",
      },
      stepType: {
        type: "string",
        title: "stepType",
      },
      description: {
        type: "string",
        title: "Step Description",
      },
      condition: {
        type: "string",
        title:
          "An optional condition on the step. If returns false step will be skipped (e.g {{input.age>10}})",
        pattern: REGX_FOR_VALID_STRING_EXPRESSION,
      },
      config: {
        type: "object",
        title: "Configuration",
        required: ["endpoint", "targetPath"],
        properties: {
          endpoint: {
            type: "object",
            title: "End Point",
            required: ["url"],
            properties: {
              url: {
                type: "string",
                title: "Url - REST request url",
              },
            },
          },
          targetPath: {
            type: "string",
            title:
              "Target path - nce value is received from api, define the path in which to store the result",
          },
          headerPath: {
            type: "string",
            title:
              "Define the path in which to store request/response headers of the api call. " +
              "No header values will be stored if left blank.",
          },

          cacheble: {
            type: "boolean",
            title: "Store target path results in cache",
          },

          soapRequest: {
            type: ["object", "null"],
            title: "the soap request to send to the method",
          },
          soapHeaders: {
            type: ["array"],
            title:
              "The array of soap headers. (Soap headers have no key only values)",
            items: {
              type: ["object", "string", "null"],
              pattern: REGX_FOR_VALID_STRING_EXPRESSION,
            },
          },
          httpHeaders: {
            type: ["object", "null"],
            title:
              "The HTTP headers to send in the rest request. (e.g. {'Authorization':'bearer 123'})",
          },
          headerTargetPath: {
            type: "string",
            title:
              "When set - extract the header from the soap result and save in doc in path defined in headerTargetPath",
          },
        },
        dependencies: {
          cacheble: {
            oneOf: [
              {
                properties: {
                  cacheble: {
                    enum: [true],
                  },
                  disableHeadersInKey: {
                    type: "boolean",
                    title:
                      "Disable the usage of the HTTP headers in the cache key of the results",
                    enum: [true, false],
                    default: true,
                  },
                  cacheKey: {
                    type: "string",
                    title: "Cache Key",
                  },
                  cacheCleanupCondition: {
                    type: "string",
                    title: "Cache clean-up condition",
                    pattern: REGX_FOR_VALID_STRING_EXPRESSION,
                  },
                  ttl: {
                    type: "number",
                    title: "TTL cache (minutes)",
                  },
                },
              },
              {
                properties: {
                  cacheble: {
                    enum: [false],
                  },
                },
              },
            ],
          },
        },
      },
    },
  },
  uiSchema: {
    stepType: {
      "ui:widget": "hidden",
    },
    name: {
      classNames: "hidden-ace4",
    },
    description: {
      "ui:widget": "textarea",
    },
    config: {
      "ui:order": [
        "endpoint",
        "targetPath",
        "headerPath",
        "cacheble",
        "disableHeadersInKey",
        "cacheKey",
        "cacheCleanupCondition",
        "ttl",
        "*",
      ],
      soapRequest: {
        "ui:field": "json",
      },
      soapHeaders: {
        items: {
          "ui:field": "json",
        },
      },
      httpHeaders: {
        "ui:field": "json",
      },
    },
  },
};
