import { REGX_FOR_VALID_STRING_EXPRESSION, Steps, StepSchema } from "../model";

export const reqValidationStepSchema: StepSchema = {
  stepType: Steps.REQUEST_VALIDATION,
  color: "rgb(247,225,211)",
  jsonSchema: {
    description: "REQUEST VALIDATION step definition",
    title: "Request Validation",
    type: "object",
    properties: {
      name: {
        type: "string",
        title: "Name",
      },
      stepType: {
        type: "string",
        title: "stepType",
      },
      description: {
        type: "string",
        title: "Step Description",
      },
      condition: {
        type: "string",
        title:
          "An optional condition on the step. If returns false step will be skipped (e.g {{input.age>10}})",
        pattern: REGX_FOR_VALID_STRING_EXPRESSION,
      },
      config: {
        type: "object",
        title: "Configuration",
        required: [],
        properties: {
          defaultSchema: {
            type: "string",
            title: "Request schema - default schema if not defined in api",
          },
          throwAsError: {
            type: "boolean",
            title: "Throw as error",
            enum: [true, false],
            default: true,
          },
        },
        dependencies: {
          throwAsError: {
            oneOf: [
              {
                properties: {
                  throwAsError: {
                    enum: [false],
                  },
                  targetPath: {
                    type: "string",
                    title:
                      "Target path - define the path in which to store the result",
                  },
                },
                required: ["targetPath"],
              },
              {
                properties: {
                  throwAsError: {
                    enum: [true],
                  },
                },
              },
            ],
          },
        },
      },
    },
  },
  uiSchema: {
    stepType: {
      "ui:widget": "hidden",
    },
    name: {
      classNames: "hidden-ace4",
    },
    description: {
      "ui:widget": "textarea",
    },
    config: {
      defaultSchema: {
        "ui:field": "schemaSearch",
      },
    },
  },
};
