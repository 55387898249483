import React, { useEffect } from "react";
import { UseFormReturn } from "react-hook-form";
import { Stack, TextField } from "@mui/material";
import { AceForm } from "components/AceForm";

import { GitDetails } from "../../model/workspace";

const GIT_URL_LABEL = "GIT URL";
const GIT_USERNAME_LABEL = "GIT username";
const GIT_PASSWORD_LABEL = "GIT password";
const BRANCH_LABEL = "Branch/Tag name";
const ACE_PATH_LABEL = "Path to ACE workspace";

export const GIT_URL_REQUIRED_ERROR = `${GIT_URL_LABEL} is required`;
export const GIT_PASSWORD_REQUIRED_ERROR = `${GIT_PASSWORD_LABEL} is required`;
export const BRANCH_NAME_REQUIRED_ERROR = `${BRANCH_LABEL} is required`;

export const INVALID_GIT_URL_ERROR = "URL does not match valid URL pattern";

const validUrlRegex = new RegExp("^https?://");

export type ImportWorkspaceGitFormFieldsProps = {
  form: UseFormReturn<GitDetails>;
};

const ImportWorkspaceGitFormFields: React.FC<ImportWorkspaceGitFormFieldsProps> = ({
  form,
}) => {
  const {
    register,
    setValue,
    getValues,
    formState: { errors },
  } = form;

  useEffect(() => {
    const formData = getValues();

    if (!formData.sourcePath) {
      setValue("sourcePath", "src/ace");
    }
  }, [setValue, getValues]);

  return (
    <AceForm>
      <Stack direction="column" spacing={2}>
        <TextField
          {...register("gitUrl", {
            required: GIT_URL_REQUIRED_ERROR,
            pattern: {
              value: validUrlRegex,
              message: INVALID_GIT_URL_ERROR,
            },
          })}
          variant="outlined"
          required
          label={GIT_URL_LABEL}
          data-testid="gitUrl"
          error={Boolean(errors?.gitUrl)}
          helperText={errors.gitUrl?.message}
        />
        <TextField
          {...register("gitUsername")}
          variant="outlined"
          label={GIT_USERNAME_LABEL}
          data-testid="gitUsername"
          error={Boolean(errors?.gitUsername)}
          helperText={errors.gitUsername?.message}
        />
        <TextField
          {...register("gitPassword", {
            required: GIT_PASSWORD_REQUIRED_ERROR,
          })}
          variant="outlined"
          label={GIT_PASSWORD_LABEL}
          data-testid="gitPassword"
          required
          error={Boolean(errors?.gitPassword)}
          helperText={errors.gitPassword?.message}
          type="password"
        />
        <TextField
          {...register("branchOrTagName", {
            required: BRANCH_NAME_REQUIRED_ERROR,
          })}
          variant="outlined"
          label={BRANCH_LABEL}
          data-testid="branchOrTagName"
          required
          error={Boolean(errors?.branchOrTagName)}
          helperText={errors.branchOrTagName?.message}
        />
        <TextField
          {...register("sourcePath")}
          variant="outlined"
          label={ACE_PATH_LABEL}
          data-testid="sourcePath"
          error={Boolean(errors?.sourcePath)}
          helperText={errors.sourcePath?.message}
        />
      </Stack>
    </AceForm>
  );
};

export default ImportWorkspaceGitFormFields;
