import { isRejected, Middleware } from "@reduxjs/toolkit";
import { RootState } from "../index";

/**
 * Middleware that logs errors of all rejected async thunks created via `createAsyncThunk`
 */
// eslint-disable-next-line @typescript-eslint/ban-types
export const errorLoggerMiddleware: Middleware<{}, RootState> = () => (
  next
) => (action) => {
  if (isRejected(action)) {
    const err = new Error(action.error.message);
    err.name = action.error.name;
    err.stack = action.error.stack;
    console.error(err);
  }
  return next(action);
};
