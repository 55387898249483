export const isEmpty = (
  value: string,
  helperText: (t: string) => void,
  error: (e: boolean) => void
): boolean => {
  if (value.trim().length === 0) {
    helperText("This field cannot be empty.");
    error(true);
    return true;
  }
  helperText("");
  error(false);
  return false;
};

export const isUrlValid = (
  value: string,
  helperText: (t: string) => void,
  error: (e: boolean) => void
): boolean => {
  try {
    new URL(value);
  } catch (e) {
    helperText("This is not a valid URL.");
    error(true);
    return true;
  }
  helperText("");
  error(false);
  return false;
};

export const canParseToNumber = (
  value: string,
  helperText: (t: string) => void,
  error: (e: boolean) => void
): boolean => {
  const asStrictlyParsedNumber = Number(value);
  if (isNaN(asStrictlyParsedNumber)) {
    helperText("This field needs to be a number.");
    error(true);
    return true;
  }
  helperText("");
  error(false);
  return false;
};
