import React from "react";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";

import { useAppSelector } from "../store";
import { selectIsDesignerLoading } from "../store/designer/selectors";

import BottomBar, { BOTTOM_BAR_HEIGHT } from "./BottomBar";
import LayoutLoader from "./LayoutLoader";
import LeftMenu from "./LeftMenu";
import LocalRepositoryWarning, {
  LOCAL_REPO_WARNING_BAR_HEIGHT,
} from "./LocalRepositoryWarning";

const getContent = (isDesignerLoading: boolean, children: React.ReactNode) => {
  if (isDesignerLoading) {
    return <LayoutLoader />;
  }

  return children;
};

const getContainerHeight = (showRepositoryWarning: boolean) => {
  if (showRepositoryWarning) {
    return `calc(100% - ${LOCAL_REPO_WARNING_BAR_HEIGHT}px)`;
  }

  return "100%";
};

interface MainLayoutProps {
  logo: string;
}

const MainLayout: React.FC<MainLayoutProps> = (props) => {
  const showSideNav = useAppSelector((state) => state.designer.showSideNav);
  const showRepositoryWarning = useAppSelector(
    (state) => state.designer.localRepositoryWarning
  );
  const isDesignerLoading = useAppSelector(selectIsDesignerLoading);

  return (
    <>
      {showRepositoryWarning && <LocalRepositoryWarning />}
      <Container
        disableGutters
        maxWidth={false}
        sx={{
          height: getContainerHeight(showRepositoryWarning),
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Box
          sx={{
            display: "flex",
            height: `calc(100% - ${BOTTOM_BAR_HEIGHT}px)`,
          }}
        >
          {showSideNav && <LeftMenu />}
          <Box
            sx={{
              display: "flex",
              width: "100%",
              overflowY: "hidden",
              overflowX: "auto",
            }}
          >
            <Box
              sx={{
                display: "flex",
                minWidth: {
                  xs: "1200px",
                  md: "1200px",
                  lg: "100%",
                },
              }}
            >
              {getContent(isDesignerLoading, props.children)}
            </Box>
          </Box>
        </Box>
        <BottomBar />
      </Container>
    </>
  );
};

export default MainLayout;
