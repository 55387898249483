import { REGX_FOR_VALID_STRING_EXPRESSION, Steps, StepSchema } from "../model";

enum ManipulateActionTypes {
  add = "add",
  subtract = "subtract",
  startOf = "startOf",
}

export const dateStepSchema: StepSchema = {
  stepType: Steps.DATE,
  color: "rgb(223,204,241)",
  jsonSchema: {
    description: "Date format step definition",
    title: "Date Functions",
    type: "object",
    properties: {
      name: {
        type: "string",
        title: "Name",
      },
      stepType: {
        type: "string",
        title: "stepType",
      },
      condition: {
        type: "string",
        title:
          "An optional condition on the step. If returns false step will be skipped (e.g {{input.age>10}})",
        pattern: REGX_FOR_VALID_STRING_EXPRESSION,
      },
      config: {
        type: "object",
        title: "Configuration",
        properties: {
          maps: {
            type: "array",
            minItems: 1,
            items: {
              type: "object",
              required: ["sourcePath", "format", "targetPath"],
              properties: {
                sourcePath: {
                  type: "string",
                },
                manipulate: {
                  type: "object",
                  properties: {
                    action: {
                      title: "Action",
                      type: "string",
                      enum: [
                        "",
                        ManipulateActionTypes.add,
                        ManipulateActionTypes.subtract,
                        ManipulateActionTypes.startOf,
                      ],
                      default: "",
                    },
                    amount: {
                      type: "number",
                      title: "Amount",
                    },
                    amountType: {
                      title: "Amount type",
                      type: "string",
                      enum: [
                        "",
                        "years",
                        "months",
                        "weeks",
                        "days",
                        "hours",
                        "minutes",
                        "seconds",
                        "milliseconds",
                      ],
                      default: "",
                    },
                  },
                },
                format: {
                  type: "string",
                  title:
                    "Format - the format of in which to save the date value (default: YYYY-MM-DDTHH:mm:ss)",
                  default: "YYYY-MM-DDTHH:mm:ss",
                },
                targetPath: {
                  type: "string",
                  title:
                    "Target path - target location of the source data inside the document",
                },
                append: {
                  type: "boolean",
                  title:
                    "Append -when true the value will be appended to the target path",
                },
              },
            },
          },
        },
      },
    },
  },
  uiSchema: {
    stepType: {
      "ui:widget": "hidden",
    },
    name: {
      classNames: "hidden-ace4",
    },
    description: {
      "ui:widget": "textarea",
    },
  },
};
