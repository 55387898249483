import inRange from "lodash/inRange";

/**
 * Utility to reorder entities
 * @example
 * // returns [1,3,2,4]
 * reorder([1,2,3,4],1,2);
 *
 * @param entities array of any
 * @param fromIdx
 * @param toIdx
 * @returns reordered array
 */
export const reorder = <T>(
  entities: T[],
  fromIdx: number,
  toIdx: number
): T[] => {
  if (
    fromIdx === toIdx ||
    !inRange(fromIdx, entities.length) ||
    !inRange(toIdx, entities.length)
  )
    return entities;

  const copy = [...entities];
  const [removed] = copy.splice(fromIdx, 1);
  copy.splice(toIdx, 0, removed);
  return copy;
};
