import { Workspace, WorkspaceFolder } from "model/workspace";
import { asYamlExt } from "services/fs-utils";
import { getDeserializedId } from "services/references";
import { getContentRoot } from "services/workspace";

import {
  IndexableEntityTypes,
  removeSpecificUsageOfEntity,
  updateEntityIndexes,
} from "./indexer";

function updateEntityUsage(
  entityId: string,
  consumerType: IndexableEntityTypes,
  consumerId: string,
  consumerPreviousId?: string
): void {
  if (consumerPreviousId) {
    removeSpecificUsageOfEntity(entityId, consumerPreviousId, consumerType);
  }

  updateEntityIndexes(entityId, consumerId, consumerType);
}

export type ConsumerEntity = {
  previousId?: string;
  id: string;
  entityType: IndexableEntityTypes;
};

export type ConsumedEntities<TEntities extends (string | undefined)[]> = {
  [key in WorkspaceFolder]?: TEntities;
};

type Ids = (string | undefined)[];
type Names = (string | undefined)[];

export function updateUsage(
  consumer: ConsumerEntity,
  entities: ConsumedEntities<Names>,
  workspace: Pick<Workspace, "location">
): void {
  Object.entries(entities).forEach(([folder, entities]) => {
    entities?.forEach((name) => {
      const folderName = folder as WorkspaceFolder;

      const usedInId =
        name &&
        getDeserializedId(
          getContentRoot(workspace, folderName),
          asYamlExt(name)
        );
      usedInId &&
        updateEntityUsage(
          usedInId,
          consumer.entityType,
          consumer.id,
          consumer.previousId
        );
    });
  });
}

export function updateUsageById(
  consumer: ConsumerEntity,
  entities: ConsumedEntities<Ids>
): void {
  Object.entries(entities).forEach(([_, entities]) => {
    entities?.forEach(
      (id) =>
        id &&
        updateEntityUsage(
          id,
          consumer.entityType,
          consumer.id,
          consumer.previousId
        )
    );
  });
}
