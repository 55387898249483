import { OpenAPIV3 } from "openapi-types";

import { SerializedFlow, AceApiOperation } from "../../model";
import FlowGeneratorUtils from "./FlowGeneratorUtils";
import { EntityFile } from "../../model/entityFile";

const REGEX_TO_SELECT_ALL_SYMBOLS_EXCEPT_UNDERSCORE_AND_DASH = new RegExp(
  /[^\w-]/gi
);

export default class FlowGenerator {
  generateFlow = (
    operation: AceApiOperation,
    info: OpenAPIV3.InfoObject,
    components: OpenAPIV3.ComponentsObject
  ): EntityFile<SerializedFlow> => {
    const {
      path,
      parameters,
      verb,
      tags,
      summary,
      security,
      operationId,
    } = operation;

    const generatedFlow = FlowGeneratorUtils.getNewFlow(
      path,
      verb,
      tags,
      operationId?.replace(
        REGEX_TO_SELECT_ALL_SYMBOLS_EXCEPT_UNDERSCORE_AND_DASH,
        ""
      )
    );

    const paramsData = FlowGeneratorUtils.extractParametersForSteps(
      (parameters as unknown) as OpenAPIV3.ParameterObject[]
    );

    let { sampleData, restStepHeaders } = paramsData;

    if (security) {
      const securityHeaders = FlowGeneratorUtils.getSecurityHeadersForRestStep(
        components,
        security
      );
      restStepHeaders = { ...restStepHeaders, ...securityHeaders };
    }

    const restStep = FlowGeneratorUtils.getRestStep(
      info,
      path,
      verb,
      summary || ""
    );

    const requestBody = (operation.requestBody as unknown) as OpenAPIV3.RequestBodyObject;
    const steps = generatedFlow.content.steps;
    if (requestBody) {
      steps.push(FlowGeneratorUtils.getJsonataStepForRequestBody());
      restStep.config.json = "{{requestBody}}";
      const requestSchema = FlowGeneratorUtils.extractRequestBodySchema(
        components,
        requestBody
      ) as OpenAPIV3.SchemaObject;
      if (requestSchema && requestSchema.properties) {
        sampleData = {
          ...sampleData,
          ...(requestSchema.example || requestSchema.properties),
        };
        steps.push(FlowGeneratorUtils.getAjvStep(requestSchema.properties));
        restStep.condition = "{{validations && validations.length === 0}}";
      }
    }

    if (
      paramsData.queryParams &&
      Object.keys(paramsData.queryParams).length > 0
    ) {
      steps.push(
        FlowGeneratorUtils.getJsonataStepForQueryParam(paramsData.queryParams)
      );
      restStep.config.queryParams = "{{queryParams}}";
    }

    restStep.config.headers = restStepHeaders;
    steps.push(restStep);

    generatedFlow.content.sampleData = sampleData;
    return generatedFlow;
  };
}
