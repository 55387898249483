import { useDispatch } from "react-redux";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";
import { Box, Link, Typography } from "@mui/material";

import { openSettings } from "../store/designer/reducers";

export const LOCAL_REPO_WARNING_BAR_HEIGHT = 30;

const LocalRepositoryWarning: React.FC = () => {
  const dispatch = useDispatch();

  const handleLinkClick = () => {
    dispatch(openSettings());
  };

  return (
    <Box
      data-testid="localrepowarning-root"
      sx={{
        width: "100%",
        height: `${LOCAL_REPO_WARNING_BAR_HEIGHT}px`,
        backgroundColor: "warning.main",
        display: "flex",
        justifyContent: "center",
      }}
    >
      <WarningAmberIcon
        sx={{ color: "danger", marginTop: "3px", marginRight: "5px" }}
      />
      <Typography variant="h6" component="h2">
        Please set up{" "}
        <Link onClick={handleLinkClick} sx={{ cursor: "pointer" }}>
          repository settings
        </Link>{" "}
        to be able to save your local changes!
      </Typography>
    </Box>
  );
};

export default LocalRepositoryWarning;
