import { REGX_FOR_VALID_STRING_EXPRESSION, Steps, StepSchema } from "../model";

export const logStepSchema: StepSchema = {
  stepType: Steps.LOG,
  color: "rgb(28, 244, 229)",
  jsonSchema: {
    title: "Logger",
    description: "Log custom business information",
    type: "object",
    properties: {
      name: {
        type: "string",
        title: "Name",
      },
      stepType: {
        type: "string",
        title: "stepType",
      },
      description: {
        type: "string",
        title: "Step Description",
      },
      condition: {
        type: "string",
        title:
          "An optional condition on the step. If returns false step will be skipped (e.g {{input.age>10}})",
        pattern: REGX_FOR_VALID_STRING_EXPRESSION,
      },
      config: {
        type: "object",
        title: "Configuration",
        required: ["message"],
        properties: {
          message: {
            type: "string",
            title: "Log message",
          },
          level: {
            title: "Log level",
            type: "string",
            enum: ["info", "debug", "warn", "error"],
            default: "info",
          },
          context: {
            type: "array",
            title: "Context",
            items: {
              type: "object",
              required: ["key"],
              properties: {
                key: {
                  type: "string",
                  title: "key - Textual description of context item",
                },
                sourcePath: {
                  type: "string",
                  title: "sourcePath - Specify path for context value",
                },
                value: {
                  type: "string",
                  title: "value - A static value for context item",
                },
              },
            },
          },
        },
      },
    },
  },
  uiSchema: {
    stepType: {
      "ui:widget": "hidden",
    },
    name: {
      classNames: "hidden-ace4",
    },
    description: {
      "ui:widget": "textarea",
    },
    config: {
      customMergeFunction: {
        "ui:field": "js",
      },
    },
  },
};
