import { REGX_FOR_VALID_STRING_EXPRESSION, Steps, StepSchema } from "../model";

export const stopStepSchema: StepSchema = {
  stepType: Steps.STOP_STEP,
  color: "rgb(28, 244, 229)",
  jsonSchema: {
    title: "Stop",
    description:
      "If condition evaluates to true then flow execution is stopped",
    type: "object",
    required: ["condition"],
    properties: {
      name: {
        type: "string",
        title: "Name",
      },
      stepType: {
        type: "string",
        title: "StepType",
      },
      description: {
        type: "string",
        title: "Step Description",
      },
      condition: {
        type: "string",
        title:
          "A Mandatory condition on the step. If true then flow execution is stopped, In case of mixed flow only sub-flow is stopped",
        pattern: REGX_FOR_VALID_STRING_EXPRESSION,
      },
    },
  },
  uiSchema: {
    stepType: {
      "ui:widget": "hidden",
    },
    name: {
      classNames: "hidden-ace4",
    },
    description: {
      "ui:widget": "textarea",
    },
  },
};
