import { Workspace, WorkspaceFolder } from "../model/workspace";

import { MoveFolder, SaveFolder } from "./entityService.types";
import {
  deleteFolder,
  moveFolder,
  renameFolder,
  writeFolder,
} from "./fs-utils";

/**
 * Save folder to workspace entity folder
 * @param workspace
 * @param folder
 * @param pathToFolder
 */
export const saveEntityFolder: SaveFolder = async (
  workspace,
  folder,
  pathToFolder
) => {
  try {
    await writeFolder(workspace, folder, pathToFolder);
  } catch (e) {
    console.error(e);
    throw new Error(
      `New ${folder} folder at path "${pathToFolder}" could not be saved`
    );
  }
};

export const renameEntityFolder: MoveFolder = async (
  currentFolderName,
  workspace,
  folder,
  folderId,
  newName
) => {
  try {
    return await renameFolder(
      currentFolderName,
      workspace,
      folder,
      folderId,
      newName
    );
  } catch (e) {
    console.error(e);
    throw new Error(`Folder could not be renamed`);
  }
};

export const moveEntityFolder: MoveFolder = async (
  currentFolderName,
  workspace,
  folder,
  folderId,
  newName
) => {
  try {
    return await moveFolder(
      currentFolderName,
      workspace,
      folder,
      folderId,
      newName
    );
  } catch (e) {
    console.error(e);
    throw new Error(`Folder could not be moved`);
  }
};

export const deleteEntityFolder = async (
  folderId: string,
  folder: WorkspaceFolder,
  workspace: Workspace
): Promise<void> => {
  try {
    await deleteFolder(folderId, workspace, folder);
  } catch (e) {
    console.error(e);
    throw new Error(`Could not delete folder`);
  }
};
