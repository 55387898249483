/**
 * Queue that executes promises one after another and keeps reference to original promise.
 * @example
 * const gitPush = async (...gitPushArgs) => ...;
 * const executeGitPushViaQueue = promiseQueue(gitPush)
 * // call
 * await executeGitPushViaQueue(...gitPushArgs);
 * // or
 * executeGitPushViaQueue(...gitPushArgs).then(...);
 *
 * @param fn function to bind to queue
 */
export function promiseQueue<
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  TParams extends any[],
  TReturn,
  TFn extends (...args: TParams) => Promise<TReturn>
>(fn: TFn): TFn {
  const queue: Promise<void>[] = [];

  const wrapper = (...args: TParams) =>
    new Promise<TReturn>((resolve, reject) => {
      const fnExecutor = () =>
        fn(...args)
          .then(resolve, reject)
          .finally(() => {
            queue.shift();
          });

      if (!queue.length) {
        queue.push(fnExecutor());
      } else {
        const lastPromise = queue[queue.length - 1];
        queue.push(lastPromise.finally(fnExecutor));
      }
    });

  return wrapper as TFn;
}

export class UniversalPromiseQueue {
  private queue: Promise<void>[] = [];

  public add<TReturn>(fn: () => Promise<TReturn>): Promise<TReturn> {
    return new Promise<TReturn>((resolve, reject) => {
      const fnExecutor = () =>
        fn()
          .then(resolve, reject)
          .finally(() => {
            this.queue.shift();
          });

      if (!this.queue.length) {
        this.queue.push(fnExecutor());
      } else {
        const lastPromise = this.queue[this.queue.length - 1];
        this.queue.push(lastPromise.finally(fnExecutor));
      }
    });
  }
}
