import { isRejected, TypedStartListening } from "@reduxjs/toolkit";

import { GIT_ERROR_BRANCH_HAS_DIVERGED } from "../../services/git-utils";
import { setWorkspaceHasConflictsFlagAction } from "../designer/actions";
import { RootState } from "../index";

/**
 * Listener for Redux's listener middleware that reacts to rejected with the Branch divergence error
 * async thunks and raises conflicts flag.
 *
 * @example
 * // declare listener
 * export const listenerMiddleware = createListenerMiddleware<RootState>();
 * addBranchDivergenceListener(listenerMiddleware.startListening);
 *
 * // add it to the store
 * const store = configureStore({
 *   reducer, middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(listenerMiddleware.middleware)
 * })
 *
 * @param startListening `createListenerMiddleware.startListening` from redux
 */
export const addBranchDivergenceListener = (
  startListening: TypedStartListening<RootState>
): void => {
  startListening({
    predicate: (action) =>
      isRejected(action) &&
      action.error.message === GIT_ERROR_BRANCH_HAS_DIVERGED,
    effect: (action, { dispatch }) => {
      console.warn(
        `Branch divergence error. Error originates from action: ${action.type}`
      );
      dispatch(setWorkspaceHasConflictsFlagAction());
    },
  });
};
