import { FC, useState } from "react";
import {
  Box,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
} from "@mui/material";

import { useAppDispatch, useAppSelector } from "../store";
import {
  moveWorkspaceToAnotherBranchAction,
  resetRemoteToWorkspaceAction,
  resetWorkspaceToRemoteAction,
} from "../store/workspaces/conflictResolutionActions";

import { notify } from "./utils/notify";
import ButtonComponent from "./ButtonComponent";

const ConflictResolutionDialog: FC = () => {
  const [newBranchName, setNewBranchName] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const hasWorkspaceConflicts = useAppSelector(
    (state) => state.designer.hasWorkspaceConflicts
  );
  const dispatch = useAppDispatch();

  const withLoading = (fn: () => Promise<void>) => async () => {
    setIsLoading(true);
    await fn();
    setIsLoading(false);
  };

  const moveWorkspace = withLoading(async () => {
    const result = await dispatch(
      moveWorkspaceToAnotherBranchAction(newBranchName)
    );
    notify(
      result,
      dispatch,
      `Changes of the current workspace were moved to the new branch ${newBranchName}`
    );
  });

  return (
    <Dialog open={hasWorkspaceConflicts}>
      <DialogTitle>Resolve workspace conflicts</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Current branch has diverged. Please choose one of the following
          methods to resolve the conflict.
        </DialogContentText>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            zIndex: 99,
            pointerEvents: "none",
          }}
        >
          {isLoading && (
            <CircularProgress data-testid="conflict-resolution-loading" />
          )}
        </Box>
        <Box
          flexDirection="column"
          display="flex"
          sx={{ mt: 1, "> *": { my: 1 } }}
        >
          <ButtonComponent
            variant="outlined"
            disabled={isLoading}
            onClick={withLoading(async () => {
              const result = await dispatch(resetWorkspaceToRemoteAction());
              notify(
                result,
                dispatch,
                "Workspace was reset to the remote version"
              );
            })}
            text="Reset workspace to remote"
          />
          <ButtonComponent
            variant="outlined"
            disabled={isLoading}
            onClick={withLoading(async () => {
              const result = await dispatch(resetRemoteToWorkspaceAction());
              notify(
                result,
                dispatch,
                "Remote workspace was reset to the current version"
              );
            })}
            text="Reset remote workspace to local"
          />
          <TextField
            value={newBranchName}
            onChange={(e) =>
              setNewBranchName((e.target as HTMLInputElement).value ?? "")
            }
            onKeyPress={(e) => {
              if (newBranchName && e.key === "Enter") {
                moveWorkspace();
              }
            }}
            disabled={isLoading}
            margin="dense"
            id="name"
            label="New branch name"
            type="text"
            fullWidth
            variant="standard"
          />
          <ButtonComponent
            variant="outlined"
            disabled={newBranchName.length === 0 || isLoading}
            onClick={moveWorkspace}
            text="Move workspace to the new branch"
          />
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default ConflictResolutionDialog;
