import { createEntityAdapter, createSlice } from "@reduxjs/toolkit";
import { RootState } from "..";
import { Remote } from "../../model/workspace";

import { loadRemotesAction, deleteRemoteAction } from "./actions";

export const remotesAdapter = createEntityAdapter<Remote>({
  selectId: (remote) => remote.id,
});

export const remoteSelectors = remotesAdapter.getSelectors<RootState>(
  (state) => state.remotes
);

const initialState = remotesAdapter.getInitialState();

const slice = createSlice({
  name: "remotes",
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(loadRemotesAction.fulfilled, (state, action) => {
      return remotesAdapter.setAll(state, action.payload);
    });
    builder.addCase(deleteRemoteAction.fulfilled, (state, action) => {
      return remotesAdapter.removeOne(state, action.payload.id);
    });
  },
});

export default slice.reducer;
