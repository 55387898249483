/* eslint-disable no-mixed-operators */
/* eslint-disable no-magic-numbers */
import { hslToRgb, rgbToHex } from "@mui/material";

// Perform hashing and seeded random number generation (not supported by Math.random)
// Source: https://stackoverflow.com/a/47593316/17181414

const hashSeed = (input: string): number[] => {
  // cyrb128
  let h1 = 1779033703,
    h2 = 3144134277,
    h3 = 1013904242,
    h4 = 2773480762;

  for (let i = 0, k; i < input.length; i++) {
    k = input.charCodeAt(i);
    h1 = h2 ^ Math.imul(h1 ^ k, 597399067);
    h2 = h3 ^ Math.imul(h2 ^ k, 2869860233);
    h3 = h4 ^ Math.imul(h3 ^ k, 951274213);
    h4 = h1 ^ Math.imul(h4 ^ k, 2716044179);
  }

  h1 = Math.imul(h3 ^ (h1 >>> 18), 597399067);
  h2 = Math.imul(h4 ^ (h2 >>> 22), 2869860233);
  h3 = Math.imul(h1 ^ (h3 >>> 17), 951274213);
  h4 = Math.imul(h2 ^ (h4 >>> 19), 2716044179);
  return [
    (h1 ^ h2 ^ h3 ^ h4) >>> 0,
    (h2 ^ h1) >>> 0,
    (h3 ^ h1) >>> 0,
    (h4 ^ h1) >>> 0,
  ];
};

const generateRandomNumber = (seed: string): number => {
  let [a, b, c, d] = hashSeed(seed);

  // sfc32
  a >>>= 0;
  b >>>= 0;
  c >>>= 0;
  d >>>= 0;
  let t = (a + b) | 0;
  a = b ^ (b >>> 9);
  b = (c + (c << 3)) | 0;
  c = (c << 21) | (c >>> 11);
  d = (d + 1) | 0;
  t = (t + d) | 0;
  c = (c + t) | 0;
  return (t >>> 0) / 4294967296;
};

export const generatedDimHexColor = (seed: string): string => {
  const randomValue = generateRandomNumber(seed);
  const maxDegrees = 360;
  const hue = Math.floor(randomValue * maxDegrees);

  const rgb = hslToRgb(`hsla(${hue}, 55%, 45%, 1)`);
  return rgbToHex(rgb);
};
