import { REGX_FOR_VALID_STRING_EXPRESSION, Steps, StepSchema } from "../model";

export const jsonRpcStepSchema: StepSchema = {
  stepType: Steps.JSON_RPC,
  color: "rgb(247,225,211)",
  jsonSchema: {
    description: "JSON-RPC step definition",
    title: "JSON-RPC2.0",
    type: "object",
    properties: {
      name: {
        type: "string",
        title: "Name",
      },
      stepType: {
        type: "string",
        title: "stepType",
      },
      description: {
        type: "string",
        title: "Step Description",
      },
      condition: {
        type: "string",
        title:
          "An optional condition on the step. If returns false step will be skipped (e.g {{input.age>10}})",
        pattern: REGX_FOR_VALID_STRING_EXPRESSION,
      },
      config: {
        type: "object",
        title: "Configuration",
        required: ["endpoint", "targetPath"],
        properties: {
          targetPath: {
            type: "string",
            title:
              "Target path - define the path in which to store the result returned when executing the api",
          },
          endpoint: {
            type: "object",
            title: "End Point",
            required: ["url", "httpMethod"],
            properties: {
              url: {
                type: "string",
                title: "Url - JSON-RPC request url",
              },
              httpMethod: {
                type: "string",
                title: "HTTP Method",
                enum: ["GET", "POST"],
              },
            },
          },
          jsonRpc: {
            type: "array",
            title: "JSON-RPC Request Object",
            minItems: 1,
            items: {
              type: "object",
              required: ["method"],
              properties: {
                method: {
                  type: "string",
                  title: "JSON-RPC Method Name",
                },
                id: {
                  type: "string",
                  title: "JSON-RPC ID",
                },
                params: {
                  type: ["object", "string", "array"],
                  pattern: REGX_FOR_VALID_STRING_EXPRESSION,
                  title: "JSON-RPC Params",
                },
              },
            },
          },
          queryParams: {
            type: ["object", "null"],
            title: "the query params to send in the rest request",
          },
          headers: {
            type: ["object", "null"],
            title: "The HTTP headers to send in the rest request",
          },
          cacheble: {
            type: "boolean",
            title: "Store target path results in cache",
          },
        },
        dependencies: {
          cacheble: {
            oneOf: [
              {
                properties: {
                  cacheble: {
                    enum: [true],
                  },
                  cacheKey: {
                    type: "string",
                    title: "Cache Key",
                  },
                  cacheCleanupCondition: {
                    type: "string",
                    title: "Cache clean-up condition",
                    pattern: REGX_FOR_VALID_STRING_EXPRESSION,
                  },
                  ttl: {
                    type: "number",
                    title: "TTL cache (minutes)",
                  },
                },
              },
              {
                properties: {
                  cacheble: {
                    enum: [false],
                  },
                },
              },
            ],
          },
        },
      },
    },
  },
  uiSchema: {
    stepType: {
      "ui:widget": "hidden",
    },
    name: {
      classNames: "hidden-ace4",
    },
    description: {
      "ui:widget": "textarea",
    },
    config: {
      jsonRpc: {
        items: {
          params: {
            "ui:field": "json",
          },
        },
      },
      queryParams: {
        "ui:field": "json",
      },
      headers: {
        "ui:field": "json",
      },
    },
  },
};
