import { createSlice } from "@reduxjs/toolkit";
import cloneDeep from "lodash/cloneDeep";

import { StepsSchemasState } from "../../model/stepsSchemasState";

import { loadStepsSchemas } from "./actions";
import { name } from "./sliceName";
import { adaptSchema } from "./stepSchemaAdapters";

const stepsSchemasSlice = createSlice({
  name: name,
  initialState: {} as StepsSchemasState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(loadStepsSchemas.fulfilled, (state, action) => {
      const { payload } = action;

      payload.forEach((stepSchema) => {
        const copy = cloneDeep(stepSchema);
        adaptSchema(copy);
        state[stepSchema.stepType] = copy;
      });
    });
  },
});

export default stepsSchemasSlice.reducer;
