import { REGX_FOR_VALID_STRING_EXPRESSION, Steps, StepSchema } from "../model";

export const cleanObjectStepSchema: StepSchema = {
  stepType: Steps.CLEAN_OBJECT,
  color: "rgba(154,183,211,0.6)",
  jsonSchema: {
    description: "Remove Data step definition",
    title: "JSON Clean",
    type: "object",
    properties: {
      name: {
        type: "string",
        title: "Name",
      },
      stepType: {
        type: "string",
        title: "stepType",
      },
      description: {
        type: "string",
        title: "Step Description",
      },
      condition: {
        type: "string",
        title:
          "An optional condition on the step. If returns false step will be skipped (e.g {{input.age>10}})",
        pattern: REGX_FOR_VALID_STRING_EXPRESSION,
      },
      config: {
        type: "object",
        title: "Configuration",
        properties: {
          paths: {
            type: "array",
            title: "Paths to remove",
            minItems: 1,
            items: {
              type: "object",
              required: ["removePath"],
              properties: {
                removePath: {
                  type: "string",
                  title: "Remove path - the path in the document to remove",
                },
              },
            },
          },
        },
      },
    },
  },
  uiSchema: {
    stepType: {
      "ui:widget": "hidden",
    },
    name: {
      classNames: "hidden-ace4",
    },
    description: {
      "ui:widget": "textarea",
    },
  },
};
