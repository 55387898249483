import {
  EnvVariable,
  EnvVariables,
  SerializedEnvVariable,
  SerializedEnvVariables,
} from "../model/variable";

import { GetEntity, SaveEntity } from "./entityService.types";
import { getFileDisplayName, readFile, saveFile } from "./fs-utils";
import { deserializeChildId } from "./references";

/**
 * Obtains the environment variables from workspace
 * @param id variable id
 * @param workspace workspace
 * @returns environment variable stored in file system
 */

export const getVariables: GetEntity<EnvVariables> = async (id, workspace) => {
  try {
    const fileVariables = await readFile(id, workspace, "variables");
    const fileName = getFileDisplayName(id, workspace, "variables");
    return deserializeVariables(
      id,
      fileName,
      fileVariables as SerializedEnvVariables
    );
  } catch (e) {
    console.error(e);
    throw new Error(`Variable with the id "${id}" can not be retrieved`);
  }
};

/**
 * Saves Variable to workspace
 * @param variable Environment Variables to save
 * @param workspace workspace
 * @returns saved Serialized Environment Variable
 */
export const saveEnvVariables: SaveEntity<EnvVariables> = async (
  variable,
  workspace
) => {
  try {
    const serializedVariable = mapSerializedVariable(variable);
    await saveFile(
      variable.id,
      workspace,
      "variables",
      serializedVariable,
      variable.name
    );
    return variable;
  } catch (e) {
    console.error(e);
    throw new Error(`Variable env can not be saved`);
  }
};

export const mapSerializedVariable = (
  variable: EnvVariables
): SerializedEnvVariables => {
  const { id, ...rest } = variable;
  const variables: SerializedEnvVariable[] = rest.variables.map((v) => ({
    name: v.name,
    value: v.value,
  }));
  return { variables };
};

const deserializeVariable = (
  variableGroupId: string,
  { name, value }: SerializedEnvVariable
): EnvVariable => ({
  id: deserializeChildId(name, variableGroupId),
  name,
  value,
});

export const deserializeVariables = (
  id: string,
  fileName: string,
  fileVariables: SerializedEnvVariables
): EnvVariables => ({
  variables: fileVariables.variables.map((v) => deserializeVariable(id, v)),
  id,
  name: fileName,
});
