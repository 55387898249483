import React, { useState } from "react";
import { useSelector } from "react-redux";
import { TabContext } from "@mui/lab";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import { Dialog, DialogTitle, Tab } from "@mui/material";

import { RootState, useAppDispatch } from "../../store";
import { closeWorkspaceManager } from "../../store/designer/reducers";

import WorkspaceManagerCreate from "./WorkspaceManagerCreate";
import WorkspaceManagerLocal from "./WorkspaceManagerLocal";
import WorkspaceManagerRemote from "./WorkspaceManagerRemote";

export const WorkspaceManager: React.FC = () => {
  const dispatch = useAppDispatch();
  const [tab, setTab] = useState("create");
  const open = useSelector<RootState, boolean>(
    (state) => state.designer.workspaceManagerOpen
  );

  const handleClose = () => {
    dispatch(closeWorkspaceManager());
  };

  return (
    <Dialog open={open} onClose={handleClose} data-testid="workspace-manager">
      <TabContext value={tab}>
        <DialogTitle>
          <TabList
            indicatorColor="primary"
            onChange={(event, value) => {
              setTab(value);
            }}
          >
            <Tab label="Local" value="local" sx={{ minWidth: 160 }} />
            <Tab label="Remote" value="remote" sx={{ minWidth: 160 }} />
            <Tab label="Create new" value="create" sx={{ minWidth: 160 }} />
          </TabList>
        </DialogTitle>
        <TabPanel sx={{ paddingTop: "0px" }} value="local">
          <WorkspaceManagerLocal />
        </TabPanel>
        <TabPanel sx={{ paddingTop: "0px" }} value="remote">
          <WorkspaceManagerRemote />
        </TabPanel>
        <TabPanel value="create">
          <WorkspaceManagerCreate />
        </TabPanel>
      </TabContext>
    </Dialog>
  );
};

export default WorkspaceManager;
