import ConsoleLogger from "./consoleLogger";
import { RuntimeLogger } from "./runtimeLogger";

const CACHE_PREFIX = "logger_cache_";

const defaultLoggersCache = new Map<string, ConsoleLogger>();

export const createLogger = (name?: string): RuntimeLogger => {
  const cacheKey = CACHE_PREFIX + name;
  const logger = defaultLoggersCache.get(cacheKey);
  if (logger === undefined) {
    const newLogger = new ConsoleLogger(name);
    defaultLoggersCache.set(cacheKey, newLogger);
    return newLogger;
  }
  return logger;
};
