import { createAction, createAsyncThunk } from "@reduxjs/toolkit";

import { Schema } from "../../model/schemas";
import { pushDeletedFolderToGit } from "../../services/git-utils";
import {
  deleteSchema,
  getSchema,
  getSchemaId,
  moveSchema,
  saveSchema,
  serializeSchema,
} from "../../services/schemas";
import {
  addEntityToGitActionCreator,
  deleteAndPushActionCreator,
  deleteEntityActionCreator,
  deleteFolderActionCreator,
  moveAndPushActionCreator,
  moveEntityActionCreator,
  moveFolderActionCreator,
  openEntityActionCreator,
  refreshEntitiesActionCreator,
  renameFolderActionCreator,
  storeAndPushActionCreator,
  storeEntityActionCreator,
  storeFolderActionCreator,
} from "../utils/actionFactory";
import { assertWorkspace } from "../utils/assertEntities";
import { selectSelectedWorkspace } from "../workspaces/selectors";
import { RootState } from "..";

import { nameSelector } from "./selectors";
import { name } from "./sliceName";

export const refreshSchemaAction = refreshEntitiesActionCreator(
  name,
  getSchema,
  serializeSchema
);
export const openSchemasAction = openEntityActionCreator(name, getSchema);

export const storeSchemasAction = storeEntityActionCreator(
  name,
  saveSchema,
  "schemas"
);

export const moveSchemasAction = moveEntityActionCreator(
  name,
  moveSchema,
  "schemas"
);

export const updateWithSchemaDraftAction = createAction<Schema>(
  `${name}/updateWithSchemaDraft`
);

export const storeSchemasFolderAction = storeFolderActionCreator(
  name,
  "schemas"
);

export const renameSchemasFolderAction = renameFolderActionCreator(
  name,
  "schemas"
);

export const moveSchemasFolderAction = moveFolderActionCreator(name, "schemas");
export const overwriteSchemasFolderAction = moveFolderActionCreator(
  name,
  "schemas",
  "overwrite"
);

export const deleteSchemasAction = deleteEntityActionCreator(
  name,
  deleteSchema,
  "schemas"
);

export const deleteSchemasFolderAction = deleteFolderActionCreator(
  name,
  "schemas"
);

export const addSchemaToGitAction = addEntityToGitActionCreator(
  name,
  "schemas"
);

export const addRemovedSchemasFolderToGitAction = addEntityToGitActionCreator(
  name,
  "schemas",
  pushDeletedFolderToGit
);

export const storeAndPushSchemaAction = storeAndPushActionCreator(
  name,
  nameSelector,
  storeSchemasAction,
  addSchemaToGitAction
);

export const moveAndPushSchemaAction = moveAndPushActionCreator(
  name,
  moveSchemasAction,
  addSchemaToGitAction
);

export const deleteAndPushSchemaAction = deleteAndPushActionCreator(
  name,
  deleteSchemasAction,
  addSchemaToGitAction
);

export const deleteAndPushSchemasFolderAction = deleteAndPushActionCreator(
  name,
  deleteSchemasFolderAction,
  addRemovedSchemasFolderToGitAction
);

export const cancelSchemaChanges = createAsyncThunk<
  void,
  string,
  { state: RootState }
>("schemas/cancelSchemaChanges", async (schemaId, { dispatch, getState }) => {
  const workspace = selectSelectedWorkspace(getState());

  if (!workspace) {
    return;
  }

  const schemaFile = await getSchema(schemaId, workspace);
  await dispatch(updateWithSchemaDraftAction(schemaFile));
});

export const openSchemaByPathAction = createAsyncThunk<
  Schema,
  string,
  { state: RootState }
>(`${name}/open`, async (path, { getState, dispatch }) => {
  const workspace = selectSelectedWorkspace(getState());
  assertWorkspace(workspace);
  const id = await getSchemaId(path, workspace);
  return await dispatch(openSchemasAction(id)).unwrap();
});
