import { createSelector } from "@reduxjs/toolkit";

import { FileNode } from "../../../model/file";
import { WorkspaceFolder } from "../../../model/workspace";
import { DropdownOption } from "../../../utils/dropdownOption";
import { ALL_NODES_ID } from "../../designer/constants";
import { RootState } from "../../index";
import { selectNodes } from "../../workspaces/selectors";
import { omitExtension } from "../path";

const getDropdownOptionsFromNodes = (nodes: Array<FileNode>) =>
  nodes.map<DropdownOption>(({ path }) => ({
    value: path,
    label: omitExtension(path),
  }));

export const selectDropdownOptions = createSelector(
  (state: RootState, folder: WorkspaceFolder) =>
    selectNodes(state, folder, ALL_NODES_ID),
  getDropdownOptionsFromNodes
);
