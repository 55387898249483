import React from "react";
import { SvgIcon } from "@mui/material";

const ACEBackgroundLogo: React.FC = () => {
  return (
    <SvgIcon
      viewBox={"150 0 320 240"}
      style={{ width: "280px", height: "210px" }}
      data-testid="acebglogo-root"
    >
      <g opacity="0.1">
        <path
          d="M472.259 232.725L385.33 10.9213C383.658 5.88034 381.15 2.51968 379.479 1.67952C378.643 1.67952 374.464 0.839355 368.612 0.839355H332.67C324.312 0.839355 319.297 4.20001 317.625 10.0812L229.859 232.725C229.023 235.245 228.188 237.766 228.188 238.606C228.188 239.446 229.023 240.286 231.531 240.286H274.16C277.503 240.286 280.011 238.606 280.847 236.085L282.519 231.884L289.205 215.081L296.728 195.757L350.224 63.0115L383.658 146.188L403.719 194.917L420.436 236.085C421.272 238.606 423.779 240.286 427.123 240.286H471.424C473.931 240.286 474.767 237.766 472.259 232.725Z"
          fill="#174078"
        />
        <path
          d="M153.799 139.468C250.759 189.878 324.315 173.074 412.08 110.902C458.053 78.1358 483.129 132.746 437.156 161.312C341.032 221.804 244.908 204.16 153.799 139.468Z"
          fill="#009FE3"
        />
      </g>
    </SvgIcon>
  );
};

export default ACEBackgroundLogo;
