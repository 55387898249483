import { ErrorHandler } from "../../model/error-handlers";
import { createRedoableSlice } from "../utils/redoableSliceFactory";

import {
  deleteErrorHandlerAction,
  openErrorHandlerAction,
  refreshErrorHandlersAction,
  storeErrorHandlerAction,
} from "./actions";
import { name } from "./sliceName";

const errorHandlersSlice = createRedoableSlice<ErrorHandler>({
  name,
  reducers: {},
  predefinedThunks: {
    openEntityAction: openErrorHandlerAction,
    storeEntityAction: storeErrorHandlerAction,
    deleteEntityAction: deleteErrorHandlerAction,
    refreshEntitiesAction: refreshErrorHandlersAction,
  },
});

export const { update, add, remove, redo, undo } = errorHandlersSlice.actions;

export default errorHandlersSlice.reducer;
