import { REGX_FOR_VALID_STRING_EXPRESSION, Steps, StepSchema } from "../model";

export const transformStepSchema: StepSchema = {
  stepType: Steps.TRANSFORM,
  color: "rgb(255,255,0,0.4)",
  jsonSchema: {
    description: "DOC transformation according to the mapper object",
    title: "Key-name Transform",
    type: "object",
    properties: {
      name: {
        type: "string",
        title: "Name",
      },
      stepType: {
        type: "string",
        title: "stepType",
      },
      description: {
        type: "string",
        title: "Step Description",
      },
      condition: {
        type: "string",
        title:
          "An optional condition on the step. If returns false step will be skipped (e.g {{input.age>10}})",
        pattern: REGX_FOR_VALID_STRING_EXPRESSION,
      },
      config: {
        type: "object",
        title: "Configuration",
        required: ["mapper", "targetPath"],
        properties: {
          mapper: {
            type: "string",
            title: "Mapper object",
            minLength: 1,
          },
          targetPath: {
            type: "string",
            title: "Target path - define the path in which to store the result",
          },
        },
      },
    },
  },
  uiSchema: {
    stepType: {
      "ui:widget": "hidden",
    },
    name: {
      classNames: "hidden-ace4",
    },
    description: {
      "ui:widget": "textarea",
    },
    config: {
      mapper: {
        "ui:field": "js",
      },
    },
  },
};
