import { OpenTabbableIdsKeys, SelectedTabbableKeys } from "./workspace";

type TabbableEntity = {
  selectedIdKey: SelectedTabbableKeys;
  openIdsKey: OpenTabbableIdsKeys;
};

export type Tabbable = "flow";
type TabbableEntityConfig = Record<Tabbable, TabbableEntity>;

/**
 * Configuration for entities that support tabs and are stored in workspace
 */
export const tabbableEntityConfig: TabbableEntityConfig = {
  flow: { selectedIdKey: "selectedFlowId", openIdsKey: "flowIds" },
};
