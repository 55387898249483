import { PayloadAction } from "@reduxjs/toolkit";
import { OpenAPIV3 } from "openapi-types";

import { AceApiOperation } from "../../model";
import { createRedoableSlice } from "../utils/redoableSliceFactory";
import { selectEntity } from "../utils/redoableSliceSelectors";

import {
  deleteApiAction,
  openApiAction,
  refreshApisAction,
  storeApiAction,
} from "./actions";
import { removeApiOperation, upsertApiOperation } from "./mutators";
import { name } from "./sliceName";

const apisSlice = createRedoableSlice({
  name,
  reducers: {
    deleteOperation(
      state,
      action: PayloadAction<{
        id: string;
        path: string;
        verb: OpenAPIV3.HttpMethods;
      }>
    ) {
      const { id, path, verb } = action.payload;
      const api = selectEntity(state, id);
      if (!api) return state;
      removeApiOperation(api.present, path, verb);
    },
    saveOperation(
      state,
      action: PayloadAction<{
        id: string;
        operation: AceApiOperation;
        oldOperation?: AceApiOperation;
      }>
    ) {
      const { id, operation, oldOperation } = action.payload;
      const api = selectEntity(state, id);
      if (!api) return state;
      upsertApiOperation(api.present, operation, oldOperation);
    },
  },
  predefinedThunks: {
    openEntityAction: openApiAction,
    storeEntityAction: storeApiAction,
    deleteEntityAction: deleteApiAction,
    refreshEntitiesAction: refreshApisAction,
  },
});

export const {
  update,
  add,
  remove,
  redo,
  undo,
  deleteOperation,
  saveOperation,
} = apisSlice.actions;

export default apisSlice.reducer;
