import { REGX_FOR_VALID_STRING_EXPRESSION, Steps, StepSchema } from "../model";

export const mongodbStepSchema: StepSchema = {
  stepType: Steps.MONGODB,
  color: "rgb(95, 206, 165)",
  jsonSchema: {
    description: "Connection to the mongodb",
    title: "MongoDB",
    type: "object",
    required: ["connection", "database"],
    properties: {
      name: {
        type: "string",
        title: "Name",
      },
      stepType: {
        type: "string",
        title: "stepType",
      },
      description: {
        type: "string",
        title: "Step Description",
      },
      condition: {
        type: "string",
        title:
          "An optional condition on the step. If returns false step will be skipped (e.g {{input.age>10}})",
        pattern: REGX_FOR_VALID_STRING_EXPRESSION,
      },
      connection: {
        title:
          "Connection string (mongodb://[username:password@]host1[:port1][,...hostN[:portN]][/[defaultauthdb][?options]])",
        type: "string",
      },
      database: {
        title: "Database name",
        type: "string",
      },
      config: {
        type: "object",
        title: "Configuration",
        required: [],
        properties: {
          actions: {
            type: "array",
            title: "Actions",
            default: [
              {
                action: "find",
                query: {},
                options: {
                  sort: {},
                  projection: {},
                },
                filter: {},
                payload: [],
                updateDoc: {
                  $set: {},
                },
              },
            ],
            items: {
              type: "object",
              required: ["target", "action", "collection"],
              properties: {
                action: {
                  title: "Action",
                  type: "string",
                  enum: ["find", "insert", "update", "delete", "aggregate"],
                  default: "find",
                },
                collection: {
                  title: "Collection name",
                  type: "string",
                },
                target: {
                  title:
                    "Target path - target location of the source data inside the document",
                  type: "string",
                },
              },
              dependencies: {
                action: {
                  oneOf: [
                    {
                      properties: {
                        action: {
                          enum: ["find"],
                        },
                        query: {
                          type: ["object", "string"],
                          pattern: REGX_FOR_VALID_STRING_EXPRESSION,
                          title: "Query",
                        },
                        options: {
                          type: ["object", "string"],
                          pattern: REGX_FOR_VALID_STRING_EXPRESSION,
                          title: "Options",
                        },
                      },
                    },
                    {
                      properties: {
                        action: {
                          enum: ["aggregate"],
                        },
                        query: {
                          type: "array",
                          pattern: REGX_FOR_VALID_STRING_EXPRESSION,
                          title: "Query",
                        },
                        options: {
                          type: ["object", "string"],
                          pattern: REGX_FOR_VALID_STRING_EXPRESSION,
                          title: "Options",
                        },
                      },
                    },
                    {
                      properties: {
                        action: {
                          enum: ["insert"],
                        },
                        payload: {
                          type: ["array", "string"],
                          pattern: REGX_FOR_VALID_STRING_EXPRESSION,
                          title: "Payload",
                        },
                      },
                    },
                    {
                      properties: {
                        action: {
                          enum: ["update"],
                        },
                        filter: {
                          type: ["object", "string"],
                          pattern: REGX_FOR_VALID_STRING_EXPRESSION,
                          title: "Filter",
                        },
                        updateDoc: {
                          type: ["object", "string"],
                          pattern: REGX_FOR_VALID_STRING_EXPRESSION,
                          title: "Updated item",
                        },
                      },
                    },
                    {
                      properties: {
                        action: {
                          enum: ["delete"],
                        },
                        query: {
                          type: ["object", "string"],
                          pattern: REGX_FOR_VALID_STRING_EXPRESSION,
                          title: "Query",
                        },
                      },
                    },
                  ],
                },
              },
            },
          },
        },
      },
    },
  },
  uiSchema: {
    stepType: {
      "ui:widget": "hidden",
    },
    name: {
      classNames: "hidden-ace4",
    },
    description: {
      "ui:widget": "textarea",
    },
    config: {
      actions: {
        items: {
          query: {
            "ui:field": "json",
          },
          options: {
            "ui:field": "json",
          },
          filter: {
            "ui:field": "json",
          },
          payload: {
            "ui:field": "json",
          },
          updateDoc: {
            "ui:field": "json",
          },
        },
      },
    },
  },
};
