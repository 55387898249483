type ObjectKey = string | number | symbol;
/**
 * Type preserving `Object.keys`
 * @param obj
 * @constructor
 */
export const ObjKeys = <T extends Record<ObjectKey, unknown>>(
  obj: T
): (keyof T)[] => Object.keys(obj) as (keyof T)[];

/**
 * Type preserving `Object.entries`
 * @param obj
 * @constructor
 */
export const ObjEntries = <T extends Record<ObjectKey, unknown>>(
  obj: T
): [keyof T, T[keyof T]][] => Object.entries(obj) as [keyof T, T[keyof T]][];

/**
 * Type preserving `Object.values`
 * @param obj
 * @constructor
 */
export const ObjValues = <T extends Record<ObjectKey, unknown>>(
  obj: T
): T[keyof T][] => Object.values(obj) as T[keyof T][];
