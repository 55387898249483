import { createAction, createAsyncThunk } from "@reduxjs/toolkit";

import {
  getVariables,
  mapSerializedVariable,
  saveEnvVariables,
} from "../../services/variables";
import {
  addEntityToGitActionCreator,
  openEntityActionCreator,
  refreshEntitiesActionCreator,
} from "../utils/actionFactory";
import { assertWorkspace } from "../utils/assertEntities";
import { selectEntity } from "../utils/redoableSliceSelectors";
import { selectSelectedWorkspace } from "../workspaces/selectors";
import { RootState } from "..";

import { name } from "./sliceName";

export const openEnvironementVariablesAction = openEntityActionCreator(
  name,
  getVariables
);

export const refreshVariablesAction = refreshEntitiesActionCreator(
  name,
  getVariables,
  mapSerializedVariable
);
export const addVariablesToGitAction = addEntityToGitActionCreator(
  name,
  "variables"
);

export const deleteVariableAction = createAction<{
  variableFileId: string;
  variableId: string;
}>(`${name}/deleteVariable`);

export const deleteVariableAndPush = createAsyncThunk<
  void,
  { variableFileId: string; variableId: string },
  { state: RootState }
>(
  `${name}/deleteVariableAndPush`,
  async ({ variableFileId, variableId }, { dispatch }) => {
    await dispatch(deleteVariableAction({ variableFileId, variableId }));
    await dispatch(
      storeAndPushVariablesAction({
        id: variableFileId,
      })
    ).unwrap();
  }
);

export const storeAndPushVariablesAction = createAsyncThunk<
  void,
  { id: string },
  { state: RootState }
>(`${name}/storeAndPush`, async ({ id }, { dispatch, getState }) => {
  const variables = selectEntity(getState().variables, id);

  if (!variables) {
    throw new Error("No variables file present for id:" + id);
  }

  const workspace = selectSelectedWorkspace(getState());
  assertWorkspace(workspace);
  await saveEnvVariables(variables.present, workspace);
  await dispatch(
    addVariablesToGitAction({
      id,
    })
  ).unwrap();
});
