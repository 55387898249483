import { createSelector } from "@reduxjs/toolkit";

import { ErrorHandler } from "../../model";
import { GenericState } from "../utils/redoableSliceFactory";
import { RootState } from "..";

export const selectErrorHandlers = (
  state: RootState
): GenericState<ErrorHandler> => state.errorHandlers;

export const selectPresentErrorHandlers = createSelector(
  selectErrorHandlers,
  (errorHandlers: GenericState<ErrorHandler>) =>
    errorHandlers.map((e) => e.present)
);

export const nameSelector = (errHandler: ErrorHandler): string =>
  errHandler.name;

export const selectErrorHandlersDropdownOptions = createSelector(
  selectPresentErrorHandlers,
  (errorHandlers) =>
    errorHandlers.map(({ name }) => ({
      label: name,
      value: name,
    }))
);
