import { FC } from "react";
import { CircularProgress } from "@mui/material";
import { Box } from "@mui/system";

export interface LayoutLoaderProps {
  verticalCenter?: boolean;
}

const LayoutLoader: FC<LayoutLoaderProps> = ({ verticalCenter }) => (
  <Box
    display="flex"
    justifyContent="center"
    alignItems="center"
    width="100%"
    height={verticalCenter ? "100%" : undefined}
  >
    <CircularProgress size={60} />
  </Box>
);

export default LayoutLoader;
