import { createAsyncThunk, isRejected } from "@reduxjs/toolkit";

import { Remote } from "../../model/workspace";
import { deleteRemote, loadRemotes } from "../../services/workspace";
import { switchWorkspaceAction } from "../settings/actions";
import { assertSettings } from "../utils/assertEntities";
import { openWorkspaceAction } from "../workspaces/actions";
import { workspaceSelectors } from "../workspaces/selectors";
import { RootState } from "..";

export const loadRemotesAction = createAsyncThunk<
  Remote[],
  void,
  { state: RootState }
>("remotes/load", async (_, { getState }) => {
  const settings = getState().settings;
  assertSettings(settings);
  return await loadRemotes(
    settings.repositoryUrl,
    settings.repositoryToken,
    settings.repositoryUsername
  );
});

export const selectRemoteAction = createAsyncThunk<
  void,
  string,
  { state: RootState }
>("remotes/select", async (workspaceName, { dispatch, getState }) => {
  const workspace = workspaceSelectors
    .selectAll(getState())
    .find((item) => item.name === workspaceName);

  let workspaceId;

  if (workspace !== undefined) {
    workspaceId = workspace.id;
  } else {
    const settings = getState().settings;

    const openAction = await dispatch(
      openWorkspaceAction({
        ...settings,
        workspaceName: workspaceName,
      })
    );

    if (isRejected(openAction)) {
      throw new Error("Failed to open workspace: " + openAction.error.message);
    }

    workspaceId = openAction.payload.id;
  }

  await dispatch(switchWorkspaceAction(workspaceId)).unwrap();
});

export const deleteRemoteAction = createAsyncThunk<
  Remote,
  Remote,
  { state: RootState }
>("remotes/delete", async (workspace, { getState, dispatch }) => {
  const settings = getState().settings;

  assertSettings(settings);
  await deleteRemote(
    workspace,
    settings.repositoryUrl,
    settings.repositoryToken,
    settings.repositoryUsername
  );
  await dispatch(loadRemotesAction());
  return workspace;
});
