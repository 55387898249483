import { Draft } from "@reduxjs/toolkit";
import { RootState } from "../index";
import { FileNode } from "../../model/file";
import { Workspace, WorkspaceFolder } from "../../model/workspace";
import { selectSelectedWorkspace } from "../workspaces/selectors";
import cloneDeep from "lodash/cloneDeep";
import get from "lodash/get";
import { FileInfo } from "../workspaces/actions";

export const updateWorkspaceFolder = (
  state: RootState,
  updatedFolder: { folderPath: WorkspaceFolder; files: FileNode[] }
): void => {
  const workspace = selectSelectedWorkspace(state);
  if (!workspace) {
    return;
  }

  const { folderPath, files } = updatedFolder;
  state.workspaces.entities[workspace.id]![folderPath] = files;
};

export function hideFile(
  state: Draft<RootState>,
  objectPath: string[],
  entityFolder: WorkspaceFolder
): void {
  const workspace = selectSelectedWorkspace(state);
  if (!workspace) {
    return;
  }
  const updatedWorkspace = removeFileFromWorkspace(
    objectPath,
    workspace,
    entityFolder
  );
  state.workspaces.entities[updatedWorkspace.id] = updatedWorkspace;
}

export function showFile(
  state: Draft<RootState>,
  fileInfo: FileInfo,
  entityFolder: WorkspaceFolder
): void {
  const workspace = selectSelectedWorkspace(state);
  if (!workspace) {
    return;
  }
  const updateWorkspace = insertFileIntoWorkspace(
    fileInfo,
    workspace,
    entityFolder
  );
  state.workspaces.entities[updateWorkspace.id] = updateWorkspace;
}

function insertFileIntoWorkspace(
  fileInfo: FileInfo,
  ws: Workspace,
  entityFolder: WorkspaceFolder
): Workspace {
  const { file, objectPath } = fileInfo;
  const { objectPath: folderObjectPath, fileIdx } = getContainingFolderInfo(
    objectPath
  );
  const workspace = cloneDeep(ws);
  const workspaceFolder = workspace[entityFolder];
  const folder = get(workspaceFolder, folderObjectPath, workspaceFolder);
  folder.splice(fileIdx, 0, file);
  return workspace;
}

function removeFileFromWorkspace(
  objectPath: string[],
  ws: Workspace,
  entityFolder: WorkspaceFolder
) {
  const { objectPath: folderObjectPath, fileIdx } = getContainingFolderInfo(
    objectPath
  );
  const workspace = cloneDeep(ws);
  const workspaceFolder = workspace[entityFolder];
  const folder = get(workspaceFolder, folderObjectPath, workspaceFolder);
  folder.splice(fileIdx, 1);
  return workspace;
}

function getContainingFolderInfo(fileObjectPath: string[]) {
  const objectPath = [...fileObjectPath];
  const fileIdx = objectPath.splice(-1, 1)[0];
  return { objectPath, fileIdx };
}
