import { Steps } from "../model";

export type OverrideConfig = {
  key: string;
  valueToOverride: unknown;
  valueToCompare: unknown;
};

const disableHeadersInKeyCustomConfig: OverrideConfig = {
  key: "config.disableHeadersInKey",
  valueToOverride: false,
  valueToCompare: undefined,
};

export const STEPS_DEFAULT_OVERRIDE_CONFIG_MAP: Record<
  string,
  OverrideConfig[]
> = {
  [Steps.REST]: [disableHeadersInKeyCustomConfig],
  [Steps.REST_NEW]: [disableHeadersInKeyCustomConfig],
  [Steps.SOAP]: [disableHeadersInKeyCustomConfig],
  [Steps.SOAP_NEW]: [disableHeadersInKeyCustomConfig],
  [Steps.SOAP_PURE]: [disableHeadersInKeyCustomConfig],
};
