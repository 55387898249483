import { REGX_FOR_VALID_STRING_EXPRESSION, Steps, StepSchema } from "../model";

export const multiflowStepSchema: StepSchema = {
  stepType: Steps.MULTIFLOW,
  color: "rgb(189,208,196)",
  jsonSchema: {
    description: "Multiflow step definition",
    title: "Multi-flow (deprecated)",
    type: "object",
    properties: {
      name: {
        type: "string",
        title: "Name",
      },
      stepType: {
        type: "string",
        title: "stepType",
      },
      description: {
        type: "string",
        title: "Step Description",
      },
      condition: {
        type: "string",
        title:
          "An optional condition on the step. If returns false step will be skipped (e.g {{input.age>10}})",
        pattern: REGX_FOR_VALID_STRING_EXPRESSION,
      },
      config: {
        type: "object",
        title: "Configuration",
        properties: {
          flowIds: {
            type: "array",
            title: "List of flows to execute",
            items: {
              type: "string",
              title: "Flow name",
            },
          },
        },
      },
    },
  },
  uiSchema: {
    stepType: {
      "ui:widget": "hidden",
    },
    name: {
      classNames: "hidden-ace4",
    },
    description: {
      "ui:widget": "textarea",
    },
    config: {
      flowIds: {
        items: {
          "ui:field": "flowSearch",
        },
      },
    },
  },
};
