import React from "react";

import { Remote } from "../../model/workspace";
import ButtonComponent from "../ButtonComponent";
import { ConfirmationDialog } from "../dialogs";

interface DeleteWorkspaceDialogProps {
  open: boolean;
  handleClose: () => void;
  handleDelete: (workspace: Remote) => void;
  workspace: Remote;
}

export const DeleteWorkspaceDialog: React.FC<DeleteWorkspaceDialogProps> = (
  props: DeleteWorkspaceDialogProps
) => {
  const { open, handleClose, handleDelete, workspace } = props;

  return (
    <ConfirmationDialog
      open={open}
      title="delete workspace"
      handleClose={handleClose}
      renderCancelButton={(defaultProps) => (
        <ButtonComponent {...defaultProps} onClick={handleClose} />
      )}
      renderOkButton={(defaultProps) => (
        <ButtonComponent
          {...defaultProps}
          text="delete"
          onClick={() => {
            handleDelete(workspace);
            handleClose();
          }}
        />
      )}
    >
      <>
        Do you really want to delete <strong>{workspace?.name}</strong>{" "}
        workspace? This cannot be undone.
      </>
    </ConfirmationDialog>
  );
};
