import { SerializedApi } from "../model";
import { SerializedEnvVariables } from "../model/variable";

export const createEmptyApi = (): SerializedApi => ({
  info: { title: "Dynamic APIs", version: "1.0.0" },
  openapi: "3.0.0",
  paths: {},
});

export const createEmptyVariable = (): SerializedEnvVariables => ({
  variables: [],
});
