import { ElementType, forwardRef } from "react";
import { LoadingButton, LoadingButtonProps } from "@mui/lab";
import CircularProgress from "@mui/material/CircularProgress";
import { styled } from "@mui/material/styles";

const PREFIX = "ButtonComponent";

const classes = {
  startIcon: `${PREFIX}-startIcon`,
  contained: `${PREFIX}-contained`,
  outlined: `${PREFIX}-outlined`,
  text: `${PREFIX}-text`,
};

const StyledButton = styled(LoadingButton)(({ theme }) => ({
  [`& .${classes.startIcon}`]: {
    marginRight: "13px",
  },
  [`&.${classes.contained}`]: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.white,
    boxShadow: "none",
    minWidth: "130px",
    "&:hover": {
      backgroundColor: theme.palette.primary.main,
      boxShadow: "0px 2px 2px rgba(24, 35, 48, 0.3)",
    },
    "&:focus": {
      backgroundColor: theme.palette.primaryVariant,
      boxShadow:
        "0px 4px 4px rgba(0, 0, 0, 0.25), 0px 8px 8px rgba(24, 35, 48, 0.2)",
    },
    "&:active": {
      backgroundColor: theme.palette.secondary.main,
    },
    "&:disabled": {
      color: theme.palette.grey[600],
      backgroundColor: theme.palette.grey[300],
    },
  },
  [`&.${classes.outlined}`]: {
    color: theme.palette.primary.main,
    borderColor: theme.palette.primary.main,
    "&:hover": {
      backgroundColor: "#E3F1F8",
    },
    "&:focus": {
      backgroundColor: "#9ED0EB",
    },
    "&:active": {
      backgroundColor: "#BADDF0",
    },
    "&:disabled": {
      backgroundColor: "transparent",
      color: theme.palette.grey[500],
      borderColor: theme.palette.grey[500],
    },
  },
  [`&.${classes.text}`]: {
    color: theme.palette.primary.main,
    "&:hover": {
      backgroundColor: "#E3F1F8",
    },
    "&:focus": {
      backgroundColor: "#9ED0EB",
    },
    "&:active": {
      backgroundColor: "#BADDF0",
    },
    "&:disabled": {
      backgroundColor: "transparent",
      color: theme.palette.grey[500],
    },
  },
}));

export interface ButtonComponentProps extends LoadingButtonProps {
  variant: "text" | "outlined" | "contained";
  text?: string;
  component?: ElementType;
  toLink?: string;
}

const ButtonComponent = forwardRef<HTMLButtonElement, ButtonComponentProps>(
  (props, ref) => {
    const {
      variant,
      text,
      disabled,
      startIcon,
      onClick,
      children,
      component,
      toLink,
      sx,
      ...rest
    } = props;

    const linkProps = {
      component: toLink ? component : undefined,
      onClick: toLink ? undefined : onClick,
      to: toLink ?? undefined,
    };

    return (
      <StyledButton
        ref={ref}
        sx={{
          height: "39px",
          borderRadius: { xs: "2px" },
          padding: "10px 16px",
          ...sx,
        }}
        classes={{
          contained: classes.contained,
          outlined: classes.outlined,
          text: classes.text,
          startIcon: classes.startIcon,
        }}
        variant={variant}
        disabled={disabled}
        startIcon={startIcon}
        loadingIndicator={
          <CircularProgress
            color="inherit"
            size={16}
            data-testid="reusable-button-loading"
          />
        }
        {...rest}
        {...linkProps}
      >
        {text}
        {children}
      </StyledButton>
    );
  }
);

export default ButtonComponent;
