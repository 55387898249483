import { REGX_FOR_VALID_STRING_EXPRESSION, Steps, StepSchema } from "../model";

export const ajvStepSchema: StepSchema = {
  stepType: Steps.AJV,
  color: "rgb(254, 255, 224)",
  jsonSchema: {
    title: "Schema Validator",
    description: "Another JSON Schema Validator",
    type: "object",
    properties: {
      name: {
        type: "string",
        title: "Name",
      },
      stepType: {
        type: "string",
        title: "stepType",
      },
      description: {
        type: "string",
        title: "Step Description",
      },
      condition: {
        type: "string",
        title:
          "An optional condition on the step. If returns false step will be skipped (e.g {{input.age>10}})",
        pattern: REGX_FOR_VALID_STRING_EXPRESSION,
      },
      config: {
        type: "object",
        title: "Configuration",
        required: ["targetPath", "dataPath"],
        properties: {
          dataPath: {
            type: "string",
            title: "Data path - define path to validated object",
          },
          schemaReference: {
            type: "string",
            title: "JSON schema (overrides JSON inline schema)",
          },
          schema: {
            type: ["object", "null"],
            title: "JSON inline schema",
          },
          error: {
            type: "boolean",
            title: "Throw as error",
          },
          targetPath: {
            type: "string",
            title: "Target path - define the path in which to store the result",
          },
        },
      },
    },
  },
  uiSchema: {
    stepType: {
      "ui:widget": "hidden",
    },
    name: {
      classNames: "hidden-ace4",
    },
    description: {
      "ui:widget": "textarea",
    },
    config: {
      schema: {
        "ui:field": "json",
      },
      schemaReference: {
        "ui:field": "schemaSearch",
      },
    },
  },
};
