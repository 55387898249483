export enum FileType {
  File,
  Directory,
}

export interface FileNode {
  id: string; // id of the node, used as a reference Id for entity that represents the container that is loaded from this file
  absolutePath: string; // absolute path of file
  path: string; // relative path of file, relative to the "contentRoot", for example for root "/src/ace/develop/apis/fields" it is "id.yaml"
  children: Folder;
  type: FileType;
  date: string;
  readonly displayName: string; // display name, usually file or directory name without extension
}

export type Folder = Array<FileNode>;
